import { css } from "@emotion/react";
import { theme } from "@minted/minted-components";

export const absolute = css`
  position: absolute;
`;

export const bottomSpacerOnly = css`
  &:only-child {
    margin-bottom: 20px;
  }
`;

export const flex = css`
  display: flex;
`;

/*
  "-ms-flex" is used here because we want to override the flex styling
  created by Autoprefixer and as such this way will affect only IE
  browsers, while preserving regular flex for compatibility reasons.
*/
export const flex1 = css`
  -ms-flex: 0 1 auto;
  flex: 1;
`;

export const flex5 = css`
  -ms-flex: 5 1 auto;
  flex: 5;
`;

export const flex9 = css`
  -ms-flex: 9 1 auto;
  flex: 9;
`;

export const flexRow = css`
  flex-direction: row;
`;

export const flexMd = css`
  ${theme.media.greaterThan(theme.breakpoints.medium)(`
    display: flex;
  `)}
`;

export const hide = css`
  display: none;
`;

export const hideLast = css`
  &:last-child {
    display: none;
  }
`;

export const hideMd = css`
  ${theme.media.greaterThan(theme.breakpoints.medium)(`
    display: none;
  `)}
`;

export const m20 = css`
  margin: 20px;
`;

export const mx0Md = css`
  ${theme.media.greaterThan(theme.breakpoints.medium)(`
    margin-right: 0;
    margin-left: 0;
  `)}
`;

export const relative = css`
  position: relative;
`;
