import difference from "lodash/difference";
import isEqual from "lodash/isEqual";
import merge from "lodash/merge";
import omit from "lodash/omit";
import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

const clearStaged = (state = {}, { meta }) => {
  const { id, patchFields } = meta;

  if (patchFields) {
    const data = (state[id] && state[id].data) || {};

    if (difference(Object.keys(data), patchFields).length !== 0) {
      return {
        ...state,
        [id]: {
          ...state[id],
          data: omit(data, patchFields),
        },
      };
    }
  }

  return omit(state, id);
};

const initialCreationState = {
  data: {},
  errors: {},
  params: {},
  pending: false,
};

const creationEntry = handleActions(
  {
    COMMIT_RESOURCE_CREATION_FULFILLED: (state, { meta, payload }) => ({
      ...state,
      pending: false,
    }),
    COMMIT_RESOURCE_CREATION_PENDING: (state, { meta, payload }) => ({
      ...state,
      pending: true,
    }),
    COMMIT_RESOURCE_CREATION_REJECTED: (state, { meta, payload }) => ({
      ...state,
      errors: payload,
      pending: false,
    }),
    STAGE_RESOURCE_CREATION: (state, { meta, payload }) => ({
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
      params: meta.params,
    }),
  },
  initialCreationState,
);

const updateExistingEntry = (state, action) =>
  state.map((item) => {
    if (isEqual(item.params, action.meta.params)) {
      return creationEntry(item, action);
    }

    return item;
  });

const create = (state = [], action) => {
  if (!action.meta || !action.meta.params) {
    return state;
  }

  const index = state.findIndex((item) =>
    isEqual(item.params, action.meta.params),
  );

  switch (action.type) {
    case "COMMIT_RESOURCE_CREATION_PENDING":
    case "COMMIT_RESOURCE_CREATION_FULFILLED":
    case "COMMIT_RESOURCE_CREATION_REJECTED":
      if (index === -1) {
        return state;
      }

      // Update existing entry
      return updateExistingEntry(state, action);
    case "STAGE_RESOURCE_CREATION":
      // New entry
      if (index === -1) {
        return [...state, creationEntry(undefined, action)];
      }

      return updateExistingEntry(state, action);
    case "CLEAR_RESOURCE_CREATION":
      return [
        ...state.filter((item) => !isEqual(item.params, action.meta.params)),
      ];
    default:
      return state;
  }
};

const initialUpdateState = {
  data: {},
  errors: {},
  patch: false,
  pending: false,
  silent: false,
};

const updateEntry = handleActions(
  {
    COMMIT_RESOURCE_UPDATE_FULFILLED: (state, { meta, payload }) => ({
      ...(state.patch
        ? Object.keys(state.data).reduce(
            (combined, key) =>
              meta.patchFields.includes(key)
                ? {
                    ...combined,
                    [key]: state.data[key],
                  }
                : combined,
            {},
          )
        : state),
      pending: false,
    }),
    COMMIT_RESOURCE_UPDATE_PENDING: (state, { meta, payload }) => ({
      ...state,
      pending: true,
    }),
    COMMIT_RESOURCE_UPDATE_REJECTED: (state, { meta, payload }) => ({
      ...state,
      errors: payload,
      pending: false,
    }),
    STAGE_RESOURCE_UPDATE: (state, { meta, payload }) => ({
      ...state,
      data: meta.deepMergeState
        ? merge({}, state.data, payload)
        : {
            ...state.data,
            ...payload,
          },
      patch: meta.patch || state.patch,
      silent: meta.silent || state.silent,
    }),
  },
  initialUpdateState,
);

const update = (state = {}, action) => {
  switch (action.type) {
    case "COMMIT_RESOURCE_UPDATE_PENDING":
    case "COMMIT_RESOURCE_UPDATE_FULFILLED":
    case "COMMIT_RESOURCE_UPDATE_REJECTED":
      if (!state.hasOwnProperty(action.meta.id)) {
        // No staged entries for this id exist. Must have already been cleared.
        return state;
      }

      return {
        ...state,
        [action.meta.id]: updateEntry(state[action.meta.id], action),
      };
    case "STAGE_RESOURCE_UPDATE":
      // TODO: verify that patches aren't being mixed with normal updates
      return {
        ...state,
        [action.meta.id]: updateEntry(state[action.meta.id], action),
      };
    case "CLEAR_RESOURCE_UPDATE":
      return clearStaged(state, action);
    default:
      return state;
  }
};

const initialRemovalState = {
  errors: {},
  pending: false,
};

const removalEntry = handleActions(
  {
    COMMIT_RESOURCE_REMOVAL_FULFILLED: (state, { meta, payload }) => ({
      ...state,
      pending: false,
    }),
    COMMIT_RESOURCE_REMOVAL_PENDING: (state, { meta, payload }) => ({
      ...state,
      pending: true,
    }),
    COMMIT_RESOURCE_REMOVAL_REJECTED: (state, { meta, payload }) => ({
      ...state,
      errors: payload,
      pending: false,
    }),
    STAGE_RESOURCE_REMOVAL: (state, { meta, payload }) => ({
      ...state,
    }),
  },
  initialRemovalState,
);

const remove = (state = {}, action) => {
  switch (action.type) {
    case "COMMIT_RESOURCE_REMOVAL_PENDING":
    case "COMMIT_RESOURCE_REMOVAL_FULFILLED":
    case "COMMIT_RESOURCE_REMOVAL_REJECTED":
      if (!state.hasOwnProperty(action.meta.id)) {
        // No staged entries for this id exist. Must have already been cleared.
        return state;
      }

      return {
        ...state,
        [action.meta.id]: removalEntry(state[action.meta.id], action),
      };
    case "STAGE_RESOURCE_REMOVAL":
      return {
        ...state,
        [action.meta.id]: removalEntry(state[action.meta.id], action),
      };
    case "CLEAR_RESOURCE_REMOVAL":
      return clearStaged(state, action);
    default:
      return state;
  }
};

export { create, update, remove };

export default combineReducers({
  create,
  delete: remove,
  update,
});
