import axios from "axios";
import config from "config";
import sha1 from "js-sha1";
import parse from "url-parse";

export const hashPath = (url) => {
  const hash = sha1.create();
  const pathname = parse(url.toLowerCase()).pathname;

  hash.update(pathname);

  return hash.hex();
};

export const track = (feature, event, data) => {
  data = data || {};
  const baseUrl = config.get("mintedBaseUrl");
  const qs = new URLSearchParams({
    ...data,
    event,
    feature,
  });

  return axios(`${baseUrl}/track?${qs}`, {
    withCredentials: true,
  });
};
