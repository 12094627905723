import VideoPlayer from "../VideoPlayer";
import LightBox from "./ReactImageLightbox";
import { Global } from "@emotion/react";
import { LoadingSpinner } from "@minted/minted-components";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

const LightBoxItem = ({ media }) => {
  const [loaded, setLoaded] = useState(false);
  const [loadGracePeriodHasPassed, setLoadGracePeriodHasPassed] =
    useState(false); // To allow for a grace period before loading spinner is shown, reducing flicker

  const setLoadedTrue = useCallback(() => {
    setLoaded(true);
  }, []);
  const setLoadGracePeriodHasPassedTrue = useCallback(() => {
    setLoadGracePeriodHasPassed(true);
  }, []);

  useEffect(() => {
    setTimeout(setLoadGracePeriodHasPassedTrue, 100);
  }, [setLoadGracePeriodHasPassedTrue]);

  if (media.mediaType === "video" && media.hasProcessed) {
    return <VideoPlayer key={media.publicId} publicId={media.publicId} />;
  }

  return (
    <React.Fragment>
      <LoadingSpinner loading={!loaded && loadGracePeriodHasPassed} />
      <img
        className="ril__image"
        onError={setLoadedTrue}
        onLoad={setLoadedTrue}
        src={media.original}
      />
    </React.Fragment>
  );
};

const MediaLightBox = ({
  index,
  isOpen,
  media,
  onCloseRequest,
  onMoveNextRequest,
  onMovePrevRequest,
}) => {
  const style = {
    captionContent: {
      textAlign: "center",
      width: "100%",
    },
    modalContent: {
      width: "100%",
    },
  };

  const mainItem = media[index];
  const nextItem = media[(index + 1) % media.length];
  const prevItem = media[(index + media.length - 1) % media.length];

  const caption = mainItem.caption
    ? mainItem.caption.split("\n").map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ))
    : null;

  if (!isOpen) {
    return null;
  }

  return (
    <div>
      <LightBox
        enableZoom={false}
        hasProcessed={mainItem.hasProcessed}
        imageCaption={caption}
        mainCustomContent={<LightBoxItem key={mainItem.id} media={mainItem} />}
        nextCustomContent={<LightBoxItem key={nextItem.id} media={nextItem} />}
        onCloseRequest={onCloseRequest}
        onMoveNextRequest={onMoveNextRequest}
        onMovePrevRequest={onMovePrevRequest}
        prevCustomContent={<LightBoxItem key={prevItem.id} media={prevItem} />}
        reactModalStyle={{
          content: style.modalContent,
        }}
      />
      <Global
        styles={{
          ".ril-caption-content": style.captionContent,
        }}
      />
    </div>
  );
};

MediaLightBox.propTypes = {
  index: PropTypes.number.isRequired,
  isOpen: PropTypes.bool,
  media: PropTypes.array.isRequired,
  onCloseRequest: PropTypes.func,
  onMoveNextRequest: PropTypes.func,
  onMovePrevRequest: PropTypes.func,
};

MediaLightBox.defaultProps = {
  isOpen: false,
};

export default MediaLightBox;
