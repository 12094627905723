import StyledTopBar from "../../website/components/StyledTopBar";
import { selectSite } from "../../website/selectors";
import Confirmation from "./Confirmation";
import RSVPModal from "./RSVPModal";
import Response from "./Response";
import SearchForm from "./SearchForm";
import { css } from "@emotion/react";
import { Button } from "@minted/fancyclothes";
import { TextInput, theme } from "@minted/minted-components";
import React, { useCallback, useState } from "react";
import { connect } from "react-redux";

const rsvpPageStyles = {
  nameInput: css`
    margin-bottom: ${theme.spacing.x4};
  `,
};
const LegacyRSVPForm = ({ isPremium, isReadOnly }) => {
  const [value, setValue] = useState("");
  const [invites, setInvites] = useState(null);
  const [responses, setResponses] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = useCallback(() => {
    setValue("");
    setIsModalOpen(false);
    setResponses(null);
    setInvites(null);
  }, []);

  return (
    <>
      <form onSubmit={() => setIsModalOpen(true)}>
        <div css={rsvpPageStyles.nameInput}>
          <TextInput
            disabled={isReadOnly}
            onChange={(event) => setValue(event.target.value)}
            size="small"
            value={value || ""}
          />
        </div>
        <Button
          block={true}
          disabled={isReadOnly}
          onClick={(event) => {
            event.preventDefault();
            setIsModalOpen(true);
          }}
          text="Find RSVP"
        />
      </form>
      <RSVPModal isOpen={isModalOpen} onClose={closeModal}>
        {responses ? (
          // Guest has responded, show confirmation screen
          <Confirmation
            guestName={invites?.[0].guest.contact.name}
            isPremium={isPremium}
            onChangeResponses={() => setResponses(null)}
            responses={responses}
          />
        ) : invites?.length ? (
          // Guest has not responded but the search returned invites, show RSVP form
          <Response
            invites={invites}
            onGuestResponded={(responses) => setResponses(responses)}
            TopBarComponent={StyledTopBar}
          />
        ) : (
          // No response or invites, show search form
          <SearchForm
            initialValue={value}
            onSelectGuest={(invites) => setInvites(invites)}
            TopBarComponent={StyledTopBar}
          />
        )}
      </RSVPModal>
    </>
  );
};

const mapStateToProps = (state) => {
  const site = selectSite(state.resources.sites);

  return {
    isPremium: site.serviceTier === "premium_wedding",
  };
};

export default connect(mapStateToProps)(LegacyRSVPForm);
