export const eventFormTypes = [
  {
    text: "After Party",
    value: "after_party",
  },
  {
    text: "Bach Trip or Party",
    value: "bach_trip_or_party",
  },
  {
    text: "Bridal Luncheon",
    value: "bridal_luncheon",
  },
  {
    text: "Ceremony",
    value: "ceremony",
  },
  {
    text: "Engagement Party",
    value: "engagement_party",
  },
  {
    text: "Farewell Brunch",
    value: "farewell_brunch",
  },
  {
    text: "Reception",
    value: "reception",
  },
  {
    text: "Rehearsal Dinner",
    value: "rehearsal_dinner",
  },
  {
    text: "Welcome Party",
    value: "welcome_party",
  },
  {
    text: "Wedding Shower",
    value: "wedding_shower",
  },
  {
    text: "Other",
    value: "other",
  },
];

export const multipleChoiceQuestionType = "multiple_choice";
export const freetextQuestionType = "freetext";
export const questionTypes = [
  {
    text: "Multiple Choice",
    value: multipleChoiceQuestionType,
  },
  {
    text: "Short Answer",
    value: freetextQuestionType,
  },
];

export const SELECTED_ADDRESS_AUTOCOMPLETE = "suggestions_select";

const getVenueNameFromDescription = (descriptionVenue, addressParts) => {
  /**
   * descriptionVenue is a full address: 'Empire State Building, West 34th Street, New York, NY, USA'
   * addressParts is a object with info of the place(returned by Geocode service):
   * {
   * locality: "New York"
   * route: "West 34th Street",
   * street_number: "20",
   * ...
   * }
   *
   * first case: the description could be 20 West 34th Street(USA) or West 34th Street 20(outside USA)
   * returns the same address as venue name.
   * second case: description could be Empire State Building, West 34th Street, New York, NY, USA, so
   * we return the substring from the index 0 to index where route(West 34th Street) or locality(New York) begins, also
   * we use trim to eliminate spaces from both ends of the string and remove the last character(,) with replace.
   */
  const address1 = addressParts.route
    ? `${addressParts.street_number || ""} ${addressParts.route}`.trim()
    : "";

  const reversedAddress1 = addressParts.route
    ? `${addressParts.route} ${addressParts.street_number || ""}`.trim()
    : "";

  if (
    address1 &&
    (descriptionVenue.indexOf(address1) === 0 ||
      descriptionVenue.indexOf(reversedAddress1) === 0)
  ) {
    return address1;
  }

  if (addressParts.route) {
    // street name like west 34th
    const indexRoute = descriptionVenue.indexOf(addressParts.route);

    return descriptionVenue.substr(0, indexRoute).trim().replace(/.$/, "");
  }

  const indexLocality = descriptionVenue.indexOf(addressParts.locality); // city name like New York

  return descriptionVenue.substr(0, indexLocality).trim().replace(/.$/, "");
};

export const geocodeAutocompleteSelection = async (
  autocompleteAction,
  mapModule,
) => {
  try {
    if (autocompleteAction.type !== SELECTED_ADDRESS_AUTOCOMPLETE) {
      return;
    }

    const addressParts = await mapModule.geocode(
      autocompleteAction.payload.place_id,
    );

    const address1 = addressParts.route
      ? `${addressParts.street_number || ""} ${addressParts.route}`.trim()
      : "";

    return {
      address1,
      address2: "",
      city:
        addressParts.locality ||
        addressParts.postal_town ||
        addressParts.sublocality,
      country: addressParts.country,
      hasPhysicalAddress: true,
      state: addressParts.administrative_area_level_1,
      venue: getVenueNameFromDescription(
        autocompleteAction.payload.description,
        addressParts,
      ),
      zipCode: addressParts.postal_code,
    };
  } catch (error) {
    return {
      error,
    };
  }
};

/* eslint-disable sort-keys */
export const countryNameByCode = {
  US: "United States",
  AF: "Afghanistan",
  AX: "Aland Islands",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua and Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia",
  BA: "Bosnia and Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory",
  BN: "Brunei Darussalam",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  CV: "Cape Verde",
  KY: "Cayman Islands",
  CF: "Central African Republic",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos Islands",
  CO: "Colombia",
  KM: "Comoros",
  CG: "Congo",
  CK: "Cook Islands",
  CR: "Costa Rica",
  HR: "Croatia",
  CU: "Cuba",
  CY: "CYPRUS",
  CZ: "Czech Republic",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  ET: "Ethiopia",
  FK: "Falkland Islands",
  FO: "Faroe Islands",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  TF: "French Southern Territories",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard Island and McDonald Islands",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KP: "North Korea",
  KR: "South Korea",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Lao",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libyan Arab Jamahiriya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao",
  MK: "Macedonia",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia",
  MD: "Moldova",
  MC: "Monaco",
  MN: "Mongolia",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands",
  AN: "Netherlands Antilles",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestinian",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RE: "Reunion",
  RO: "Romania",
  RU: "Russian Federation",
  RW: "Rwanda",
  SH: "Saint Helena",
  KN: "Saint Kitts and Nevis",
  LC: "Saint Lucia",
  PM: "Saint Pierre and Miquelon",
  VC: "Saint Vincent and the Grenadines",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome and Principe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  CS: "Serbia and Montenegro",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia and the South Sandwich Islands",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Suriname",
  SJ: "Svalbard and Jan Mayen",
  SZ: "Swaziland",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syrian Arab Republic",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania",
  TH: "Thailand",
  TL: "Timor Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad and Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks and Caicos Islands",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates",
  GB: "United Kingdom",
  UM: "United States Minor Outlying Islands",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Vietnam",
  VG: "Virgin Islands GB",
  VI: "Virgin Islands US",
  WF: "Wallis and Futuna",
  EH: "Western Sahara",
  YE: "Yemen",
  ZM: "Zambia",
};
/* eslint-enable sort-keys */

export const listOfCountries = Object.entries(countryNameByCode).map(
  ([code, name]) => ({
    text: name,
    value: code,
  }),
);

export const listOfStates = [
  {
    text: "Alabama",
    value: "Alabama",
  },
  {
    text: "Alaska",
    value: "Alaska",
  },
  {
    text: "Arizona",
    value: "Arizona",
  },
  {
    text: "Arkansas",
    value: "Arkansas",
  },
  {
    text: "California",
    value: "California",
  },
  {
    text: "Colorado",
    value: "Colorado",
  },
  {
    text: "Connecticut",
    value: "Connecticut",
  },
  {
    text: "Delaware",
    value: "Delaware",
  },
  {
    text: "District of Columbia",
    value: "District of Columbia",
  },
  {
    text: "Florida",
    value: "Florida",
  },
  {
    text: "Georgia",
    value: "Georgia",
  },
  {
    text: "Hawaii",
    value: "Hawaii",
  },
  {
    text: "Idaho",
    value: "Idaho",
  },
  {
    text: "Illinois",
    value: "Illinois",
  },
  {
    text: "Indiana",
    value: "Indiana",
  },
  {
    text: "Iowa",
    value: "Iowa",
  },
  {
    text: "Kansas",
    value: "Kansas",
  },
  {
    text: "Kentucky",
    value: "Kentucky",
  },
  {
    text: "Louisiana",
    value: "Louisiana",
  },
  {
    text: "Maine",
    value: "Maine",
  },
  {
    text: "Maryland",
    value: "Maryland",
  },
  {
    text: "Massachusetts",
    value: "Massachusetts",
  },
  {
    text: "Michigan",
    value: "Michigan",
  },
  {
    text: "Minnesota",
    value: "Minnesota",
  },
  {
    text: "Mississippi",
    value: "Mississippi",
  },
  {
    text: "Missouri",
    value: "Missouri",
  },
  {
    text: "Montana",
    value: "Montana",
  },
  {
    text: "Nebraska",
    value: "Nebraska",
  },
  {
    text: "Nevada",
    value: "Nevada",
  },
  {
    text: "New Hampshire",
    value: "New Hampshire",
  },
  {
    text: "New Jersey",
    value: "New Jersey",
  },
  {
    text: "New Mexico",
    value: "New Mexico",
  },
  {
    text: "New York",
    value: "New York",
  },
  {
    text: "North Carolina",
    value: "North Carolina",
  },
  {
    text: "North Dakota",
    value: "North Dakota",
  },
  {
    text: "Ohio",
    value: "Ohio",
  },
  {
    text: "Oklahoma",
    value: "Oklahoma",
  },
  {
    text: "Oregon",
    value: "Oregon",
  },
  {
    text: "Pennsylvania",
    value: "Pennsylvania",
  },
  {
    text: "Rhode Island",
    value: "Rhode Island",
  },
  {
    text: "South Carolina",
    value: "South Carolina",
  },
  {
    text: "South Dakota",
    value: "South Dakota",
  },
  {
    text: "Tennessee",
    value: "Tennessee",
  },
  {
    text: "Texas",
    value: "Texas",
  },
  {
    text: "Utah",
    value: "Utah",
  },
  {
    text: "Vermont",
    value: "Vermont",
  },
  {
    text: "Virginia",
    value: "Virginia",
  },
  {
    text: "Washington",
    value: "Washington",
  },
  {
    text: "West Virginia",
    value: "West Virginia",
  },
  {
    text: "Wisconsin",
    value: "Wisconsin",
  },
  {
    text: "Wyoming",
    value: "Wyoming",
  },
];

export const listOfCanadianProvinces = [
  {
    text: "Alberta",
    value: "Alberta",
  },
  {
    text: "British Columbia",
    value: "British Columbia",
  },
  {
    text: "Manitoba",
    value: "Manitoba",
  },
  {
    text: "New Brunswick",
    value: "New Brunswick",
  },
  {
    text: "Newfoundland and Labrador",
    value: "Newfoundland and Labrador",
  },
  {
    text: "Northwest Territories",
    value: "Northwest Territories",
  },
  {
    text: "Nova Scotia",
    value: "Nova Scotia",
  },
  {
    text: "Nunavut",
    value: "Nunavut",
  },
  {
    text: "Ontario",
    value: "Ontario",
  },
  {
    text: "Prince Edward Island",
    value: "Prince Edward Island",
  },
  {
    text: "Quebec",
    value: "Quebec",
  },
  {
    text: "Saskatchewan",
    value: "Saskatchewan",
  },
  {
    text: "Yukon",
    value: "Yukon",
  },
];
