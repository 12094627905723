import MapMarker from "./MapMarker";
import GoogleMap from "google-map-react";
import { fitBounds } from "google-map-react/utils";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDOM from "react-dom";

const defaultCenter = {
  lat: 41.850033,
  lng: -87.6500523,
};

const defaultZoom = 10;

const createOptions = () => ({
  fullscreenControl: false,
  scrollwheel: false,
});

const distanceToMouse = (
  { positionX, positionY },
  { positionX: mouseX, positionY: mouseY },
) => {
  positionY -= 25; // Vertical offset since our markers are entirely above the actual location point.

  return Math.sqrt(
    (positionX - mouseX) * (positionX - mouseX) +
      (positionY - mouseY) * (positionY - mouseY),
  );
};

class Map extends Component {
  constructor(props) {
    super(props);

    this.onGoogleApiLoaded = this.onGoogleApiLoaded.bind(this);
    this.onChildClick = this.onChildClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.setZoom = this.setZoom.bind(this);

    this.state = {
      center: defaultCenter,
      hasValidLocations: true,
      markers: [],
      size: {
        height: 0,
        width: 0,
      },
      zoom: defaultZoom,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.focusedLocationIndex !== this.props.focusedLocationIndex &&
      this.props.focusedLocationIndex != null
    ) {
      this.setState({
        center: this.state.markers[this.props.focusedLocationIndex].position,
      });
    }
  }

  getBoundsForLocations(maps, locations) {
    const bounds = new maps.LatLngBounds();

    locations.map(({ position }) => bounds.extend(position));

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const corners = {
      ne: {
        lat: ne.lat(),
        lng: ne.lng(),
      },
      sw: {
        lat: sw.lat(),
        lng: sw.lng(),
      },
    };

    if (
      corners.ne.lat === corners.sw.lat &&
      corners.ne.lng === corners.sw.lng
    ) {
      // We have a single location so fitBounds from google-map-react won't work, just return the location as center
      return {
        center: locations[0].position,
        zoom: 12,
      };
    }
    const node = ReactDOM.findDOMNode(this._map);
    const { height, width } = node.getBoundingClientRect();

    return fitBounds(corners, {
      // by fitting the bounds into smaller space, we can be sure that Markers are not hidden
      height: height + 2 * MapMarker.MARKER_TOP,
      width: width + 2 * MapMarker.MARKER_LEFT,
    });
  }

  onGoogleApiLoaded({ map, maps }) {
    const geocoder = new maps.Geocoder();

    const promises = this.props.locations.map((location) =>
      this.geocodeLocation(geocoder, location),
    );

    // Trigger window resize so scroll bounds are updated
    window.dispatchEvent(new Event("resize"));

    Promise.all(promises).then((locations) => {
      const validLocations = locations.filter((loc) => loc !== null);

      const mapSettings =
        validLocations.length === 0
          ? {
              hasValidLocations: false,
            }
          : {
              hasValidLocations: true,
              markers: locations,
            };

      this.setState(mapSettings, () => {
        this.props.onLocationsGeocoded(mapSettings.hasValidLocations);
        if (mapSettings.hasValidLocations) {
          setTimeout(() => {
            this.setZoom(maps, validLocations);
          }, 500);
        }
      });
    });
  }

  setZoom(maps, validLocations) {
    const { center, zoom } = this.getBoundsForLocations(maps, validLocations);

    this.setState({
      center,
      zoom,
    });
  }

  geocodeLocation(geocoder, location) {
    return new Promise((resolve) => {
      geocoder.geocode(
        {
          address: location.formattedAddress,
        },
        (results, status) => {
          if (status === "OK") {
            const result = {
              ...location,
              position: {
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
              },
            };

            resolve(result);
          } else {
            resolve(null);
          }
        },
      );
    });
  }

  onChange({ center, size, zoom }) {
    this.setState({
      center: isEqual(size, this.state.size) ? center : this.state.center,
      size,
      zoom,
    });
  }

  onChildClick(key, childProps) {
    const { index } = childProps;

    this.props.focusLocation(index);
  }

  render() {
    const { apiKey, focusLocation, focusedLocationIndex, locations } =
      this.props;

    if (!this.state.hasValidLocations) {
      return (
        <div
          css={{
            alignItems: "center",
            display: "flex",
            height: "100%",
            justifyContent: "center",
          }}
        >
          No valid locations
        </div>
      );
    }

    const hashedLocations = new Map([]);

    locations.forEach((marker) => {
      if (marker.hasPhysicalAddress) {
        if (hashedLocations[marker.formattedAddress]) {
          hashedLocations[marker.formattedAddress].push(marker);
        } else {
          hashedLocations[marker.formattedAddress] = [marker];
        }
      }
    });

    return (
      <GoogleMap
        bootstrapURLKeys={{
          key: apiKey,
        }}
        center={this.state.center}
        distanceToMouse={distanceToMouse}
        hoverDistance={25}
        onChange={this.onChange}
        onChildClick={this.onChildClick}
        onGoogleApiLoaded={this.onGoogleApiLoaded}
        options={createOptions}
        ref={(node) => (this._map = node)}
        resetBoundsOnResize={true}
        yesIWantToUseGoogleMapApiInternals
        zoom={this.state.zoom}
      >
        {this.state.markers.map((marker, index) =>
          marker === null ? null : (
            <MapMarker
              events={hashedLocations[marker.formattedAddress]}
              index={index}
              key={index}
              onCloseClick={() => focusLocation(null)}
              showInfo={index === focusedLocationIndex}
              {...marker.position}
            />
          ),
        )}
      </GoogleMap>
    );
  }
}

Map.propTypes = {
  apiKey: PropTypes.string.isRequired,
  focusedLocationIndex: PropTypes.number,
  focusLocation: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  onLocationsGeocoded: PropTypes.func.isRequired,
};

export default Map;
