import API from "../core/api";
import config from "config";
import find from "lodash/find";
import forEach from "lodash/forEach";
import { useState, useEffect } from "react";

export const getCashRegistrySlug = (cashRegistryUrl) => {
  // Returns the cash registry slug by splitting the URL
  // Prod Example: minted.sendbirdie.com/r/asdf -> asdf
  // QA Example: minted-dev.sendbirdie.com/r/asdf -> asdf
  const parts = cashRegistryUrl.split("/r/");

  if (parts.length > 1) {
    return parts[1];
  }

  return null;
};

export const getCurrentUserSites = (
  settings,
  options = {
    shouldRejectOnError: true,
  },
) =>
  API.get("sites", settings).catch((error) => {
    if (options.shouldRejectOnError) {
      throw error;
    } else {
      return [];
    }
  });

export const getSiteById = (
  settings,
  options = {
    shouldRejectOnError: true,
  },
  id,
) =>
  API.get(`sites/${id}`, settings).catch((error) => {
    if (options.shouldRejectOnError) {
      throw error;
    } else {
      return [];
    }
  });

export const clearCookies = () => {
  // When a guestToken cookie sticks around longer than the guest token,
  // the guest site breaks. We need to clear the cookie and reload the page.
  if (typeof document !== "undefined" && Boolean(document)) {
    document.cookie.split(";").forEach((cookie) => {
      // Overwrite the current cookie with one that expires immediately
      document.cookie = cookie
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    window.location.reload();
  }
};

// Get site and all related data for initial render
// options:
//   shouldRejectOnError: if true, rejects promise when error happens when getting site.
//                        if false, resolves promise when error happens when getting site
export const fetchCurrentSite = (
  settings,
  options = {
    shouldRejectOnError: true,
  },
) =>
  new Promise((resolve, reject) => {
    API.get("sites/current", settings, {}, ["themeDataOverrides"])
      .then((result) => {
        const {
          accommodations,
          attendants,
          comments,
          currentUserUid,
          events,
          imageUploads,
          pages,
          photos,
          registries,
          videos,
          ...sites
        } = result;

        resolve({
          accommodations,
          attendants,
          comments,
          currentUserUid,
          events,
          "image-uploads": imageUploads,
          pages,
          photos,
          registries,
          sites: [sites],
          videos,
        });
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          clearCookies();
        }
        if (options.shouldRejectOnError) {
          reject(error);
        } else {
          resolve({
            sites: [],
          });
        }
      });
  });

// Get current user info
export const fetchCurrentUser = (settings) =>
  new Promise((resolve, reject) => {
    API.get("users/current", settings)
      .then((result) => {
        resolve({
          user: result,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          resolve({});
        } else {
          reject(error);
        }
      });
  });

export const getDesign = (productCode) =>
  API.get(`products/designs/by-product-code/${productCode}/`).catch(
    console.error,
  );

export const MATCHING_PRODUCT_MAP = {
  INV: ["INV", "IFS"],
  PLC: ["PLC", "PCF"],
  STD: ["STD", "SFS"],
  TBL: ["TBL", "TBF"],
  TYC: ["TYC", "TYF"],
};

export const getMatchingProducts = (design, product, colorway) =>
  new Promise((resolve, reject) => {
    // the matching codes must be in order of precedence

    const defaultMatchingProducts = config.get("defaultMatchingProducts");

    let DWW;

    if (product) {
      DWW = {
        code: product.code,
        image: colorway.image,
        url: product.externalUrl,
      };
    } else {
      const defaultWeddingProduct = config.get("defaultWeddingProduct");

      DWW = {
        code: defaultWeddingProduct.code,
        image: defaultWeddingProduct.colorways[0].image,
        url: `https://www.minted.com/product/wedding-websites/${defaultWeddingProduct.code}`,
      };
    }

    if (!design) {
      resolve({
        ...defaultMatchingProducts,
        DWW,
      });
    }

    if (design.products.length === 0) {
      resolve(null);
    }

    const matchingProducts = {
      DWW,
    };

    Object.entries(MATCHING_PRODUCT_MAP).forEach(
      ([kindCode, matchingKindCodes]) => {
        let matchingProduct;

        forEach(matchingKindCodes, (matchingKindCode) => {
          matchingProduct = find(design.products, [
            "kind.code",
            matchingKindCode,
          ]);
          if (matchingProduct) {
            return false;
          }
        });

        if (matchingProduct) {
          const matchingColorway = find(matchingProduct.colorways, {
            code: colorway.code,
          });

          if (matchingColorway) {
            matchingProducts[kindCode] = {
              code: matchingProduct.code,
              image: matchingColorway.image,
              url: matchingProduct.externalUrl,
            };
          }
        }

        if (!Boolean(matchingProducts[kindCode])) {
          matchingProducts[kindCode] = defaultMatchingProducts[kindCode];
        }
      },
    );

    resolve(matchingProducts);
  });

export const getDesignSuiteImage = (designProducts = [], colorway = {}) => {
  let designImage;

  const suite_product = designProducts.find(
    (product) => product?.kind?.code === "INV" || product?.kind?.code === "IFS",
  );

  if (suite_product && colorway) {
    const design_colorway = suite_product.colorways.find(
      (suite_colorway) => suite_colorway?.code === colorway?.code,
    );

    designImage =
      design_colorway?.suiteImage || config.get("defaultDesignImage");
  } else {
    designImage = config.get("defaultDesignImage");
  }

  return designImage;
};

export const cloudinaryUrl = (id, format, transformations = "") => {
  const parts = [
    config.get("cloudinaryCloudName"),
    "video",
    "upload",
    transformations,
    `${id}.${format}`,
  ];

  const path = parts.filter((part) => part).join("/");

  return `https://res.cloudinary.com/${path}`;
};

// Returns content sorted with newest records first,
//   followed by content with a position greater than 0 in ascending order
export const sortByCreatedAtAndPosition = (content) =>
  content.sort((contentA, contentB) => {
    if (contentA.position === 0 && contentB.position === 0) {
      return contentA.createdAt < contentB.createdAt ? 1 : -1;
    }

    return contentA.position > contentB.position ? 1 : -1;
  });

export const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if ("IntersectionObserver" in window) {
      const observer = new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting),
      );

      observer.observe(ref.current);

      // Remove the observer as soon as the component is unmounted
      return () => {
        observer.disconnect();
      };
    }
  }, [ref]);

  return isIntersecting;
};
