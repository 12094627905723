import { css } from "@emotion/react";
import {
  Divider,
  GlobalStyles,
  styleUtils,
  theme,
} from "@minted/minted-components";
import PropTypes from "prop-types";
import React, { Fragment, memo } from "react";

const { rem } = styleUtils;

const textStyles = css`
  width: 100%;
  word-wrap: break-word;
`;

const bannerStyles = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: ${theme.spacing.x8};
  padding-top: ${theme.spacing.x8};
  text-align: center;
  ${textStyles}

  ${theme.media.greaterThan("large")(`
    max-width: ${rem(1140)};
  `)}
`;

const bodyStyles = css`
  ${theme.typeStyles.bodyLargeStyles};
  color: #fff;
  padding-bottom: ${theme.spacing.x2};
  ${textStyles}
`;

const containerStyles = css`
  background-color: ${theme.colors.gray700};
  display: flex;
  justify-content: center;
  padding: ${theme.spacing.x6};
`;

const dividerStyles = css`
  padding-bottom: ${theme.spacing.x3};
  width: ${rem(150)};

  ${theme.media.greaterThan("large")(`
    padding-bottom: ${theme.spacing.x6};
  `)}
`;

const headingStyles = css`
  ${theme.typeStyles.bodyMediumStyles}
  ${theme.typeStyles.bodyBoldStyles}
  color: #fff;
  letter-spacing: ${rem(10)};
  padding-bottom: ${theme.spacing.x2};
  ${textStyles}

  ${theme.media.greaterThan("large")(`
    padding-bottom: ${theme.spacing.x3};
  `)}
`;

const propTypes = {
  bodyText: PropTypes.string.isRequired,
  headingText: PropTypes.string.isRequired,
};

const AnnouncementBanner = ({ bodyText, coupleNames, headingText }) => (
  <Fragment>
    <GlobalStyles />
    <div css={containerStyles}>
      <div css={bannerStyles}>
        <div css={headingStyles}>{headingText}</div>
        <div css={dividerStyles}>
          <Divider type="light" />
        </div>
        <div css={bodyStyles}>{bodyText}</div>
      </div>
    </div>
  </Fragment>
);

AnnouncementBanner.propTypes = propTypes;

export default memo(AnnouncementBanner);
