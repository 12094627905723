import byId from "./byId";
import collections from "./collections";
import staged from "./staged";
import get from "lodash/get";
import reduce from "lodash/reduce";
import { combineReducers } from "redux";

const reducer = combineReducers({
  byId,
  collections,
  name: (state = {}) => state,
  staged,
});

export default (resources) => {
  // Compute initial state for the reducer tree
  const initialResourceState = reducer(undefined, {
    type: "NONE",
  });

  const initialState = reduce(
    resources,
    (obj, model) => {
      obj[model] = {
        ...initialResourceState,
        name: model,
      };

      return obj;
    },
    {},
  );

  return (state = initialState, action) => {
    const model = get(action, "meta.model");

    if (model !== undefined) {
      return {
        ...state,
        [model]: {
          ...reducer(state[model], action),
          name: model,
        },
      };
    }

    return state;
  };
};
