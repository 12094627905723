import { SITE_TYPES } from "../core/constants";

const dekuraSiteTypeSlug = {
  [SITE_TYPES.WEDDING]: "wedding-websites",
  [SITE_TYPES.HOLIDAY]: "holiday-websites",
  [SITE_TYPES.ONLINE_INVITATION]: "online-invitations",
};

export const getThemeByProductCodePathFromSiteType = (siteType) =>
  `/api/themes/sites/${dekuraSiteTypeSlug[siteType]}/themes/by-product-code`;
export const DEFAULT_COLORWAY = "A";
export const THEME_BY_ID_PATH = "/api/themes/themes";
