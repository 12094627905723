import { contentConfiguration } from "../../content";
import { WrapperLarge } from "../../layout/website";
import Page from "../../pages/website/Page";
import Attendant from "./Attendant";
import { css } from "@emotion/react";
import { theme } from "@minted/minted-components";
import { arrayOf, array, oneOfType, shape, string } from "prop-types";
import React, { Fragment } from "react";

const grid = (layout) => css`
  ${theme.media.greaterThan(theme.breakpoints.medium)(`
    display: flex;
    flex-wrap: wrap;

    & > * {
      width: ${layout === "multi" ? "calc(5/12 * 100%)" : "100%"};
    };
  `)}
`;

const spacerStyle = css`
  flex: 1;
`;

const WeddingParty = ({ attendants, configuration, id, title }) => (
  <Page id={id} title={title}>
    <WrapperLarge>
      <div css={grid(configuration.pageLayout)}>
        {attendants.map((item, index) => (
          <Fragment key={item.id}>
            <Attendant
              configuration={configuration}
              description={item.bio}
              {...item}
            />

            {configuration.pageLayout === "multi" && index % 2 === 0 ? (
              <div css={spacerStyle} key={`spacer-${index}`} />
            ) : null}
          </Fragment>
        ))}
      </div>
    </WrapperLarge>
  </Page>
);

WeddingParty.propTypes = {
  attendants: arrayOf(
    shape({
      bio: array,
      name: string.isRequired,
      photo: oneOfType([string]),
      title: string.isRequired,
    }).isRequired,
  ).isRequired,
  configuration: contentConfiguration.configurationPropTypes,
  id: string.isRequired,
  title: string.isRequired,
};

export default WeddingParty;
