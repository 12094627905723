export const PAGE_TYPE = {
  EVENTS: "EVENTS",
  GALLERY: "GALLERY",
  GIFT_REGISTRY: "GIFT REGISTRY",
  GUEST_BOOK: "GUEST_BOOK",
  HOME: "HOME",
  OTHER: "OTHER",
  PARTY: "PARTY",
  RSVP: "RSVP",
};
