import {
  ATTENDING,
  NOT_ATTENDING,
  AWAITING_REPLY,
  SENT,
  UNSENT,
} from "../guests/constants";
import {
  arrayOf,
  number,
  shape,
  string,
  oneOf,
  oneOfType,
  bool,
} from "prop-types";

export const eventPropTypes = shape({
  address1: string,
  address2: string,
  city: string,
  date: string.isRequired,
  description: arrayOf(
    shape({
      children: arrayOf(
        shape({
          text: string,
        }),
      ),
      type: string,
    }),
  ).isRequired,
  endDate: string,
  endTime: string,
  id: number.isRequired,
  questions: arrayOf(
    shape({
      choices: arrayOf(string),
      id: number,
      questionType: oneOf(["freetext", "multiple_choice"]),
      required: bool,
      text: string,
    }),
  ),
  state: string,
  time: string,
  title: string.isRequired,
  venue: string.isRequired,
  zipCode: string,
});

export const eventResponsesPropTypes = shape({
  answers: arrayOf(
    shape({
      answer: string,
      questionId: number,
    }),
  ).isRequired,
  eventId: oneOfType([number, string]).isRequired,
  responseType: oneOf([ATTENDING, NOT_ATTENDING, AWAITING_REPLY, SENT, UNSENT]),
});
