import PropTypes from "prop-types";
import React, { cloneElement, Component, isValidElement } from "react";

export default (WrappedComponent) => {
  class RefSourceComponent extends Component {
    constructor(props) {
      super(props);
      this.connectRef = this.connectRef.bind(this);
    }

    // Clones a rendered React component with a ref injected and connects it to the ref manager
    connectRef(id, namespace, node) {
      // TODO: Detect if node is a stateless component and throw an error, since stateless components can't have refs
      if (isValidElement(node)) {
        return cloneElement(node, {
          ref: (refNode) => {
            this.context.refManager.setRef(id, namespace, refNode);
          },
        });
      }
    }

    render() {
      const props = {
        ...this.props,
        connectRef: this.connectRef,
      };

      return <WrappedComponent {...props} />;
    }
  }

  RefSourceComponent.contextTypes = {
    refManager: PropTypes.object.isRequired,
  };

  RefSourceComponent.displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  return RefSourceComponent;
};
