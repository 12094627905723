import config from "config";
import { handleActions } from "redux-actions";

const initialState = {
  doPurchaseCustomDomain: false,
  doRenewDomain: false,
  doUpgrade: false,
  guestAuthenticated: true,
  guestToken: null,
  purchaseCustomDomainUrl: `${config.get("mintedBaseUrl")}/personalize/add-to-cart/MIN-WCD-DSU`,
  renewDomainUrl: `${config.get("mintedBaseUrl")}/personalize/add-to-cart/MIN-WDR-DSU`,
  upgradeUrl: `${config.get("mintedBaseUrl")}/personalize/add-to-cart/MIN-PWW-DSU`,
};

export default handleActions(
  {
    AUTHENTICATE_GUEST_FULFILLED: (state, { payload }) => ({
      ...state,
      guestAuthenticated: true,
    }),
    FETCH_GUEST_TOKEN_FULFILLED: (state, { payload }) => ({
      ...state,
      guestToken: payload.token,
    }),
    REQUIRE_GUEST_AUTHENTICATE: (state, action) => ({
      ...state,
      guestAuthenticated: false,
    }),
    SITE_DO_DOMAIN_RENEW: (state, action) => ({
      ...state,
      doRenewDomain: true,
    }),
    SITE_DO_PURCHASE_CUSTOM_DOMAIN: (state, action) => ({
      ...state,
      doPurchaseCustomDomain: true,
    }),
    SITE_DO_UPGRADE: (state, action) => ({
      ...state,
      doUpgrade: true,
    }),
    SITE_UPDATED: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  initialState,
);
