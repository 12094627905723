import { addressbookMiddleware } from "../../guests/middleware";
import rootReducer from "../reducers";
import { applyMiddleware, compose, createStore } from "redux";
import promiseMiddleware from "redux-promise-middleware";
import { router5Middleware } from "redux-router5";
import thunk from "redux-thunk";

export default (router, initialState) => {
  const middleware = [
    thunk,
    promiseMiddleware(),
    router5Middleware(router),
    addressbookMiddleware,
  ];

  const finalCreateStore = compose(applyMiddleware(...middleware))(createStore);

  const store = finalCreateStore(rootReducer, initialState);

  if (typeof window !== "undefined") {
    window.store = store;
  }

  return store;
};
