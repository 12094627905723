import React, { createContext } from "react";

const initialState = {
  addressbookContactId: null,
};

export const DeepLinkContext = createContext(initialState);

export const DeepLinkContextProvider = ({ addressbookContactId, children }) => (
  <DeepLinkContext.Provider
    value={{
      addressbookContactId,
    }}
  >
    {children}
  </DeepLinkContext.Provider>
);
