export const makeQueryString = (
  params,
  hasMultipleQueryParamValues = false,
) => {
  if (params) {
    return (
      "?" +
      Object.keys(params)
        .map((key) =>
          (hasMultipleQueryParamValues && Array.isArray(params[key])
            ? params[key]
            : [params[key]]
          )
            .map(
              (value) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
            )
            .join("&"),
        )
        .join("&")
    );
  }

  return "";
};
