import { css } from "@emotion/react";
import { theme } from "@minted/minted-components";
import PropTypes from "prop-types";
import React from "react";

const style = css`
  display: flex;

  ${theme.media.greaterThan(theme.breakpoints.extraSmall)(`
    flex-direction: column;
  `)}
`;

const Row = ({ children }) => <div css={style}>{children}</div>;

Row.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Row;
