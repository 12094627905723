import { css } from "@emotion/react";
import { Modal, theme } from "@minted/minted-components";
import React from "react";

const exclusionStyles = {
  detailsHeader: css`
    ${theme.typeStyles.titleLargeStyles};
    color: ${theme.colors.textPrimary};
    padding-bottom: ${theme.spacing.x4};
  `,
  detailsText: css`
    ${theme.typeStyles.bodySmallStyles};
  `,
  modal: css`
    padding: ${theme.spacing.x16};

    ${theme.media.lessThan(theme.breakpoints.small)(css`
      padding: ${theme.spacing.x4};
    `)}
  `,
};

const OnlineInvitationExclusions = ({ close, isOpen }) => (
  <Modal
    mobileFullscreen={true}
    onCloseClick={close}
    open={isOpen}
    size={Modal.sizes.medium}
  >
    <div css={exclusionStyles.modal} data-cy="exclusionsModal">
      <div css={exclusionStyles.detailsHeader}>Details and exclusions</div>
      <div css={exclusionStyles.detailsText}>
        All product categories are eligible for use with our online invitation
        credit. Credit excludes Minted More membership program, Photo Op by
        Minted, art styling services, gift cards, custom design services, and
        items sold and shipped by third-party sellers (Direct from Artist and
        Wedding Marketplace). Credit does not apply to shipping and tax, and
        cannot be combined with any other offers or promotions.
      </div>
    </div>
  </Modal>
);

export default OnlineInvitationExclusions;
