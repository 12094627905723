import { getEventDateTimeComponent } from "../../events/utils";
import RichTextRenderer from "../../forms/components/RichText/RichTextRenderer";
import { countryNameByCode } from "../../guest-management/forms/constants";
import React from "react";

const smallTextStyles = {
  fontSize: "14px",
  lineHeight: "18px",
};

export default ({ event }) => (
  <div>
    <p
      css={{
        marginTop: 0,
      }}
    >
      <span css={smallTextStyles}>
        {getEventDateTimeComponent(
          event.date,
          event.time,
          event.endDate,
          event.endTime,
        )}
      </span>
    </p>
    <p>
      <span css={smallTextStyles}>
        {event.venue}
        {event.hasPhysicalAddress && (
          <span>
            <br />
            {event.address1}, {event.address2 ? `${event.address2}, ` : ""}
            {event.city}, {event.state} {event.zipCode}
            {event.country && event.country !== "US" && (
              <>
                {", "}
                {countryNameByCode[event.country]}
              </>
            )}
          </span>
        )}
      </span>
    </p>

    <RichTextRenderer value={event.description} />
  </div>
);
