import { theme } from "@minted/minted-components";

export const QUESTION_WITH_ERROR_CLASSNAME = "questionWithError";

export const EVENT_DETAILS_SPACING = {
  form: {
    desktop: {
      margin: {
        bottom: theme.spacing.x5,
        left: theme.spacing.x5,
        right: theme.spacing.x5,
        top: theme.spacing.x5,
      },
      padding: {
        bottom: theme.spacing.x2,
        left: theme.spacing.x4,
        right: theme.spacing.x4,
        top: theme.spacing.x4,
      },
    },
    mobile: {
      margin: {
        bottom: 0,
        left: theme.spacing.x4,
        right: theme.spacing.x4,
        top: theme.spacing.x4,
      },
      padding: {
        bottom: theme.spacing.x2,
        left: theme.spacing.x5,
        right: theme.spacing.x5,
        top: theme.spacing.x4,
      },
    },
  },
  summary: {
    desktop: {
      margin: {
        bottom: theme.spacing.x4,
        left: theme.spacing.x5,
        right: theme.spacing.x5,
        top: 0,
      },
      padding: {
        bottom: theme.spacing.x2,
        left: theme.spacing.x4,
        right: theme.spacing.x4,
        top: theme.spacing.x2,
      },
    },
    mobile: {
      margin: {
        bottom: theme.spacing.x4,
        left: 0,
        right: 0,
        top: 0,
      },
      padding: {
        bottom: 0,
        left: theme.spacing.x4,
        right: theme.spacing.x4,
        top: 0,
      },
    },
  },
};
