import { Column, Row } from "../../layout/website";
import LightBox from "./LightBox";
import ReactFluidGrid from "./ReactFluidGrid";
import { css } from "@emotion/react";
import {
  ActionLink,
  DesignComponent,
  TinyHeadline,
} from "@minted/fancyclothes";
import { Icon, theme } from "@minted/minted-components";
import PropTypes from "prop-types";
import React, { Component } from "react";

const { breakpoints } = theme;

const propTypes = {
  media: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string,
      id: PropTypes.string.isRequired,
      original: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["photo", "video"]),
    }).isRequired,
  ),
};

class MediaGalleryPreview extends DesignComponent {
  // Load all slideshow images into browser immediately to avoid Lightbox issues.
  componentDidMount() {
    this.props.media.forEach((media) => {
      const img = new Image();

      img.src = media.original;
    });
  }

  render() {
    const { media, openLightBox } = this.props;

    const style = {
      banner: css`
        position: absolute;
        width: 90%;
        left: 5%;
        bottom: 15px;
        padding: 20px;
        text-align: center;
        box-sizing: border-box;
        background: ${this.getRule("backgroundColor", "DesignProvider")}AA;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
      `,
      image: css`
        display: block;
        margin: 0 auto;
        max-width: 100%;
        box-sizing: border-box;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);

        ${breakpoints.sm} {
          max-height: 800px;
        }
      `,
      imageWrapper: css`
        display: inline-block;
        position: relative;
      `,
      wrapper: css`
        display: flex;
        justify-content: center;
        cursor: pointer;
      `,
    };

    const previewItem = media[0];

    return (
      <div css={fullWidthMobileStyles}>
        <Row>
          <Column>
            <div css={style.wrapper} onClick={() => openLightBox(0)}>
              <span css={style.imageWrapper}>
                <img
                  alt={previewItem.caption}
                  css={style.image}
                  src={previewItem.original}
                />
                <div css={style.banner}>
                  <div>{media.length} Items</div>
                  <ActionLink
                    onClick={() => openLightBox(0)}
                    text="View Slideshow"
                  />
                </div>
              </span>
            </div>
          </Column>
        </Row>
      </div>
    );
  }
}

MediaGalleryPreview.propTypes = {
  media: propTypes.media,
};
const MediaGridItem = ({ item, onClick }) => {
  const style = {
    captionMargin: css`
      margin-bottom: ${theme.spacing.x2};
    `,
    item: css`
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
      box-sizing: border-box;
      cursor: pointer;
      width: 100%;
    `,
    videoOverlay: css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      pointer-events: none;
      opacity: 0.7;
      text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
    `,
  };

  return (
    <div
      onClick={onClick}
      style={{
        position: "relative",
      }}
    >
      {item.mediaType === "video" ? (
        <div css={style.videoOverlay}>
          <Icon size="large" type="video-circle" />
        </div>
      ) : null}
      <img alt={item.caption} css={style.item} src={item.original} />
      <div css={style.captionMargin}>
        <TinyHeadline>{item.caption}</TinyHeadline>
      </div>
    </div>
  );
};

const fullWidthMobileStyles = css`
  position: relative;

  // overrides padding in Column component
  ${theme.media.lessThan(theme.breakpoints.small)(css`
    div {
      padding-left: 0;
      padding-right: 0;
    }
  `)}
`;

const MediaGrid = ({ isMobileView, media, openLightBox }) => {
  const reactFluidGridStyleStrategies = [
    {
      mediaQuery: `(min-width: 0) and (max-width: ${breakpoints.small})`,
      style: {
        gutterHeight: 0,
        gutterWidth: 11,
        numberOfColumns: 1,
      },
    },
    {
      mediaQuery: `(min-width: ${breakpoints.small}) and (max-width: ${breakpoints.large})`,
      style: {
        gutterHeight: 0,
        gutterWidth: 11,
        numberOfColumns: Math.min(media.length, 3),
      },
    },
    {
      mediaQuery: `(min-width: ${breakpoints.large})`,
      style: {
        gutterHeight: 0,
        gutterWidth: 11,
        numberOfColumns: Math.min(media.length, 3),
      },
    },
  ];

  return (
    <div css={fullWidthMobileStyles}>
      {isMobileView ? (
        <Row>
          {media.map((item, index) => (
            <Column key={item.id}>
              <MediaGridItem item={item} onClick={() => openLightBox(index)} />
            </Column>
          ))}
        </Row>
      ) : (
        <Row>
          <Column>
            <ReactFluidGrid
              styleStrategies={reactFluidGridStyleStrategies}
              transition="none"
            >
              {media.map((item, index) => (
                <MediaGridItem
                  item={item}
                  key={item.id}
                  onClick={() => openLightBox(index)}
                />
              ))}
            </ReactFluidGrid>
          </Column>
        </Row>
      )}
    </div>
  );
};

MediaGrid.propTypes = {
  media: propTypes.media,
};

class MediaGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      isLightBoxOpen: false,
    };

    this.openLightBox = this.openLightBox.bind(this);
    this.closeLightBox = this.closeLightBox.bind(this);
    this.moveNext = this.moveNext.bind(this);
    this.movePrev = this.movePrev.bind(this);
  }

  openLightBox(index) {
    this.setState({
      index,
      isLightBoxOpen: true,
    });
  }

  closeLightBox() {
    this.setState({
      isLightBoxOpen: false,
    });
  }

  moveNext() {
    const { media } = this.props;

    this.setState({
      index: (this.state.index + 1) % media.length,
    });
  }

  movePrev() {
    const { media } = this.props;

    this.setState({
      index: (this.state.index + media.length - 1) % media.length,
    });
  }

  render() {
    if (!this.props.media.length) {
      return null;
    }

    const MediaComponent = this.props.isPreview
      ? MediaGalleryPreview
      : MediaGrid;

    return (
      <React.Fragment>
        <LightBox
          index={this.state.index}
          isOpen={this.state.isLightBoxOpen}
          media={this.props.media}
          onCloseRequest={this.closeLightBox}
          onMoveNextRequest={this.moveNext}
          onMovePrevRequest={this.movePrev}
        />
        <MediaComponent
          isMobileView={this.props.isMobileView}
          media={this.props.media}
          openLightBox={this.openLightBox}
        />
      </React.Fragment>
    );
  }
}

MediaGallery.propTypes = {
  isPreview: PropTypes.bool,
  media: propTypes.media.isRequired,
};

export default MediaGallery;
