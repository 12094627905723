import API from "../../../core/api";
import { fetchGuestToken, getGuestTokenCookie } from "../../../website/actions";
import { css } from "@emotion/react";
import {
  Button,
  TextInput,
  theme,
  Modal,
  ModalHeader,
  ModalBody,
  Divider,
  TextLinkInline,
  useMediaQueryState,
} from "@minted/minted-components";
import React, { useCallback, useState, useMemo, useEffect } from "react";

const rsvpFormStyles = {
  button: css`
    width: 25%;
    ${theme.media.lessThan("medium")`
      width: auto;
    `};
  `,
  familyMobile: css`
    max-width: 75%;
  `,
  familyName: css`
    ${theme.typeStyles.bodySmallLineHeight};
    ${theme.typeStyles.bodyBoldStyles};
  `,
  headerWrapper: css`
    color: ${theme.colors.textPrimary};
  `,
  nameInput: css`
    margin-bottom: ${theme.spacing.x4};
    text-align: left;
  `,
  rsvpFamily: css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.x6};
  `,
  searchBar: css`
    width: 75%;
  `,
  searchBarMobile: css`
    width: 90%;
  `,
  searchContent: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
  `,
  searchHeader: css`
    ${theme.typeStyles.bodyMediumStyles};
    ${theme.typeStyles.bodyBoldStyles};
    text-align: center;
    width: 100%;
    margin-bottom: ${theme.spacing.x4};
  `,
  textLink: css`
    display: flex;
    margin-top: ${theme.spacing.x4};
    color: ${theme.colors.textSecondary};
  `,
  textLinkCta: css`
    margin-right: ${theme.spacing.base};
  `,
  wrapper: css`
    color: ${theme.colors.textPrimary};
    width: 100%;
  `,
};

const RSVPSearchModal = ({
  closeModal,
  initialSearchTerm,
  setRsvpData,
  site,
}) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [searchResults, setSearchResults] = useState([]);
  const [showAdditionsRSVP, setShowAdditionsRSVP] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [searchTermError, setSearchTermError] = useState(null);
  const mediumMediaQueryState = useMediaQueryState({
    mediaQuerySize: "medium",
  });
  const isMobile = mediumMediaQueryState === "BELOW";

  const getInvitesForContact = useCallback(
    (addressbookContactId) => {
      // Contact ID from search form is passed in here, and invites are queried
      // Araceli Acevedo is 'f02b1e4a-b21a-4a40-8e4c-ea588d23b9af' for local dev
      const guestToken = getGuestTokenCookie();
      const headers = {
        Authorization: `Guest ${guestToken}`,
      };

      API.get(
        "guest-management/rsvp-form-data",
        {
          headers,
        },
        {
          addressbook_contact_id: addressbookContactId,
        },
      ).then((responseData) => {
        if (site.isOnlineInvitation) {
          responseData.events[0].title = site.eventHeading;
        }
        setRsvpData(responseData);
      });
    },
    [setRsvpData, site.isOnlineInvitation, site.eventHeading],
  );

  const search = useCallback(
    (event) => {
      event?.preventDefault();
      if (searchTerm.length === 0) {
        setSearchTermError("You must enter a name");
      } else {
        setSearchTermError(null);
        const hasToken = !!getGuestTokenCookie();
        const tokenPromises = [];

        if (!hasToken) {
          tokenPromises.push(fetchGuestToken());
        }

        // If the promise exists, we need to wait for it, so .all will have either 0 or 1 promises.
        Promise.all(tokenPromises).then(() => {
          const guestToken = getGuestTokenCookie();
          const headers = {
            Authorization: `Guest ${guestToken}`,
          };

          API.get(
            "guest-management/search",
            {
              headers,
            },
            {
              q: searchTerm,
            },
          )
            .then((response) => {
              setSearchResults(response);
              setShowResults(true);
              setShowAdditionsRSVP(false);
              setSearchTerm("");
            })
            .catch(() => {
              setSearchTermError(
                "Sorry, we're not finding your invite. Try searching again or reach out to your host.",
              );
            });
        });
      }
    },
    [searchTerm],
  );

  useEffect(() => {
    if (initialSearchTerm) {
      search(null);
    }
    // Note: need an empty dependency array to run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const firstSearchResult = useMemo(() => {
    if (searchResults.length > 0) {
      return searchResults[0];
    }

    return null;
  }, [searchResults]);

  const additionalSearchResults = useMemo(() => {
    if (searchResults.length > 1) {
      return searchResults.slice(1);
    }

    return [];
  }, [searchResults]);

  // Used exclusively for online invitation sites only returns the party size.
  const getGuestCount = (guestNames) => `Party of ${guestNames.length}`;

  const getFormattedGuestNames = (guestNames) => {
    if (guestNames.every((name) => name === null)) {
      return `Party of ${guestNames.length}`;
    }

    const unnamedGuestsCount = guestNames.filter(
      (guestName) => !guestName,
    ).length;

    const areThereUnknownGuests = unnamedGuestsCount > 0;

    const formattedNames = guestNames.reduce((names, guest, index) => {
      let separator = ", ";

      if (index === 0) {
        separator = "";
      }
      if (
        guestNames.length > 1 &&
        index === guestNames.length - 1 &&
        !areThereUnknownGuests
      ) {
        separator = " & ";
      }
      if (guest === null) {
        return names;
      }

      return `${names}${separator} ${guest}`;
    }, "");

    if (areThereUnknownGuests) {
      return `${formattedNames} & ${unnamedGuestsCount === 1 ? "Guest" : `${unnamedGuestsCount} Guests`}`;
    }

    return formattedNames;
  };

  return (
    <div css={rsvpFormStyles.wrapper}>
      <Modal mobileFullscreen onCloseClick={closeModal} open size="medium">
        <ModalHeader>
          <div css={rsvpFormStyles.headerWrapper}>
            Find your RSVP
            {showResults && " in the list below"}
          </div>
        </ModalHeader>
        <ModalBody>
          {showResults && (
            <>
              <div>
                {firstSearchResult && (
                  <div css={rsvpFormStyles.rsvpFamily}>
                    <div>
                      <div css={rsvpFormStyles.familyName}>
                        {firstSearchResult.partyName}
                      </div>
                      <div>
                        {site.isOnlineInvitation
                          ? getGuestCount(firstSearchResult.guestNames)
                          : getFormattedGuestNames(
                              firstSearchResult.guestNames,
                            )}
                      </div>
                    </div>
                    <div css={rsvpFormStyles.button}>
                      <Button
                        expand
                        onClick={() =>
                          getInvitesForContact(
                            firstSearchResult.addressbookContactId,
                          )
                        }
                        size={Button.sizes.medium}
                        text="Select"
                      />
                    </div>
                  </div>
                )}
              </div>
              {showAdditionsRSVP && searchResults.length > 0 && (
                <>
                  <Divider title="OR" type="light" />
                  {additionalSearchResults.map((additionalResult) => (
                    <div
                      css={rsvpFormStyles.rsvpFamily}
                      key={additionalResult.addressbookContactId}
                    >
                      <div css={isMobile && rsvpFormStyles.familyMobile}>
                        <div css={rsvpFormStyles.familyName}>
                          {additionalResult.partyName}
                        </div>
                        <div>
                          {site.isOnlineInvitation
                            ? getGuestCount(additionalResult.guestNames)
                            : getFormattedGuestNames(
                                additionalResult.guestNames,
                              )}
                        </div>
                      </div>
                      <div css={rsvpFormStyles.button}>
                        <Button
                          expand
                          onClick={() =>
                            getInvitesForContact(
                              additionalResult.addressbookContactId,
                            )
                          }
                          size={Button.sizes.medium}
                          text="Select"
                          type={Button.types.secondary}
                        />
                      </div>
                    </div>
                  ))}
                  {searchResults.length > 0 && (
                    <div css={rsvpFormStyles.textLink}>
                      <div css={rsvpFormStyles.textLinkCta}>
                        (Still nothing?
                      </div>
                      <TextLinkInline
                        onClick={(event) => {
                          event.preventDefault();
                          setShowResults(false);
                        }}
                        text="Try another search"
                        type="secondaryAlternate"
                      />
                      )
                    </div>
                  )}
                </>
              )}
              {!showAdditionsRSVP && searchResults.length > 1 && (
                <>
                  <Divider title="" type="light" />
                  <div css={rsvpFormStyles.textLink}>
                    <div css={rsvpFormStyles.textLinkCta}>Not You?</div>
                    <TextLinkInline
                      onClick={(event) => {
                        event.preventDefault();
                        setShowAdditionsRSVP(true);
                      }}
                      text="Pick a different name."
                      type="secondaryAlternate"
                    />
                  </div>
                </>
              )}
            </>
          )}
          {searchResults.length <= 1 && showResults && (
            <>
              <Divider title="" type="light" />
              <div css={rsvpFormStyles.textLink}>
                <span>
                  Not finding your invite?{" "}
                  <TextLinkInline
                    onClick={(event) => {
                      event.preventDefault();
                      setShowResults(false);
                    }}
                    text="Try searching again"
                    type="secondaryAlternate"
                  />{" "}
                  or reach out to your host
                </span>
              </div>
            </>
          )}
          {!showResults && (
            <div css={rsvpFormStyles.searchContent}>
              <div
                css={
                  isMobile
                    ? rsvpFormStyles.searchBarMobile
                    : rsvpFormStyles.searchBar
                }
              >
                <div css={rsvpFormStyles.searchHeader}>
                  Enter your name to RSVP
                </div>
                <div css={rsvpFormStyles.nameInput}>
                  <TextInput
                    error={searchTermError}
                    label="Enter your name"
                    onChange={(event) => {
                      setSearchTerm(event.target.value);
                      setSearchTermError(null);
                    }}
                    onKeyDown={(event) =>
                      event.key === "Enter" && search(event)
                    }
                    size="medium"
                    touched
                    value={searchTerm}
                  />
                </div>
                <Button
                  // Disabling the search button so that demo sites can view the
                  // form but not actually make any requests to the backend
                  disabled={site.isDemoSite}
                  expand
                  onClick={search}
                  text="Find RSVP"
                  type="primary"
                />
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default RSVPSearchModal;
