import Paragraphs from "../../pages/website/Paragraphs";
import {
  getAllResources,
  getResource,
  filterPage,
  selectSite,
} from "../selectors";
import FancyClothesProvider from "./FancyClothesProvider";
import Footer from "./Footer";
import WebsiteHeader from "./WebsiteHeader";
import WebsitePages from "./WebsitePages";
import { GlobalStyles } from "@minted/minted-components";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import React, { Component } from "react";
import { connect } from "react-redux";

export class Website extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props);
  }

  render() {
    const { pages, resources, route, site, themeAuthor } = this.props;
    const filteredPages = pages.filter((page) =>
      filterPage(page, {
        ...resources,
        sites: [site],
      }),
    );

    let activePage = filteredPages.find((page) => page.slug === route.name);

    if (!activePage) {
      activePage = filteredPages.find((page) => page.slug === "home");
    }

    const pagesToRender =
      site.pageConfiguration === "multi_page" ? [activePage] : filteredPages;

    const useCondensedHeader =
      site.pageConfiguration === "multi_page" && activePage.slug !== "home";

    const textAlignment = get(activePage, "configuration.textAlignment");

    return (
      <FancyClothesProvider>
        <GlobalStyles />
        <div id="home">
          <WebsiteHeader condensed={useCondensedHeader} pages={filteredPages}>
            <Paragraphs
              text={site.welcomeText || ""}
              textAlignment={textAlignment}
            />
          </WebsiteHeader>
        </div>
        <WebsitePages pages={pagesToRender} resources={resources} site={site} />
        <Footer artist={themeAuthor} siteType={site.siteType} />
      </FancyClothesProvider>
    );
  }
}

const mapStateToProps = (state) => {
  const site = selectSite(state.resources.sites);

  const pages = getResource(state.resources.pages);
  const resources = getAllResources(state.resources);

  return {
    pages,
    resources,
    route: state.router.route,
    site,
    themeAuthor: state.theme.attribution,
  };
};

export default connect(mapStateToProps)(Website);
