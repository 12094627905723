import { scrollIntoView } from "../../../core/windowUtils";
import { openModal } from "../../../modals/actions";
import RefSource from "../../../ref-monitor/RefSource";
import { HOLIDAY_WEBSITE_CONTENT_WIDTH } from "../../constants";
import { getResource, selectSite } from "../../selectors";
import { useOnScreen } from "../../utils";
import ContentSections from "../ContentSections";
import Footer from "../Footer";
import CommentSection, { countComments } from "./CommentSection";
import NewCommentForm from "./NewCommentForm";
import TitleCardSection from "./TitleCardSection";
import { css } from "@emotion/react";
import { LargeHeadline, SmallHeadline } from "@minted/fancyclothes";
import {
  Alert,
  Icon,
  styleUtils,
  TextLink,
  theme,
} from "@minted/minted-components";
import PropTypes from "prop-types";
import React, {
  Fragment,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { connect, useDispatch } from "react-redux";

const commentCountTextAndIconStyles = css`
  ${theme.typeStyles.bodyLargeStyles};
  align-items: center;
  cursor: pointer;
  display: flex;
`;

const grayBackgroundStyles = css`
  background-color: ${theme.colors.gray200};
`;

const containerStyles = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: ${theme.spacing.x16};
`;

const cardStyles = css`
  box-sizing: border-box;
  max-width: ${HOLIDAY_WEBSITE_CONTENT_WIDTH};
  padding-left: ${theme.spacing.x4};
  padding-right: ${theme.spacing.x4};
`;

const headingContainerStyles = css`
  margin-top: ${theme.spacing.x12};
  overflow-wrap: break-word;
  text-align: center;
  width: 100%;
`;

const subheadingContainerStyles = css`
  margin-top: ${theme.spacing.x6};
  overflow-wrap: break-word;
  text-align: center;
  width: 100%;
`;

const iconPaddingStyles = css`
  padding-right: ${theme.spacing.x2};
`;

const stickyContainerStyles = css`
  align-items: center;
  bottom: 0;
  display: flex;
  height: ${styleUtils.rem(48)};
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 9;
`;

const stickyFooterStyles = css`
  align-items: center;
  background-color: ${theme.colors.gray100};
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: space-between;
  max-width: ${styleUtils.rem(600)};
  overflow-y: auto;
  padding-left: ${theme.spacing.x4};
  padding-right: ${theme.spacing.x4};
  width: 100%;
`;

const stickyFooterCenteredStyles = css`
  ${stickyFooterStyles};
  justify-content: center;
`;

const propTypes = {
  isPreview: PropTypes.bool,
  pageId: PropTypes.number,
  sections: PropTypes.arrayOf(PropTypes.object),
  site: PropTypes.shape({
    shouldDisplayFrontOfCard: PropTypes.bool.isRequired,
    welcomeHeading: PropTypes.string.isRequired,
    welcomeSubheading: PropTypes.string.isRequired,
  }).isRequired,
};

const CommentStickyFooter = ({ commentSectionRef, comments, isReadOnly }) => {
  const [isWritingComment, setIsWritingComment] = useState(false);

  const closeDrawer = useCallback(() => {
    setIsWritingComment(false);
  }, []);

  const startParentComment = useCallback(() => {
    setIsWritingComment(true);
  }, []);

  const countOfComments = useMemo(() => countComments(comments), [comments]);

  return (
    <div css={stickyContainerStyles}>
      <div
        css={
          countOfComments > 0 ? stickyFooterStyles : stickyFooterCenteredStyles
        }
      >
        {countOfComments > 0 && (
          <div
            css={commentCountTextAndIconStyles}
            onClick={() => {
              scrollIntoView(commentSectionRef.current, -60); // extra buffer space to see comments section
            }}
          >
            <div css={iconPaddingStyles}>
              <Icon type="feed" />
            </div>
            {countOfComments > 1
              ? `${countOfComments} comments`
              : `${countOfComments} comment`}
          </div>
        )}

        {!isReadOnly && (
          <Fragment>
            <NewCommentForm
              closeDrawer={closeDrawer}
              isWritingComment={isWritingComment}
            />
            <TextLink
              onClick={startParentComment}
              text="Write a Comment"
              type={TextLink.textLinkTypes.primary}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

const HolidayWebsiteContent = ({
  breakpoint,
  comments,
  connectRef,
  hasSections,
  isPreview,
  site,
}) => {
  const dispatch = useDispatch();

  // Opens inspiration modal on initial page load if user doesn't have any sections added
  useEffect(() => {
    if (isPreview && !hasSections) {
      dispatch(
        openModal("VIRALITY_UPSELL_MODAL", {
          open: true,
          selectedTab: 1,
        }),
      );
    }
    // Note: Disabling since we only want this to run on initial component mount, hence the empty dependancy array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const commentSectionRef = useRef();

  const isCommentSectionInViewport = useOnScreen(commentSectionRef);

  const isMobileView = breakpoint === "sm";

  return (
    <Fragment>
      <div css={containerStyles}>
        <Alert
          primaryText={`Your ${site.siteType} website has been archived. Only you can view this site.`}
          secondaryText={<div>Videos are not available on archived sites.</div>}
          show={site.status === "archived"}
          type={Alert.types.informative}
        />
        {connectRef(
          "title",
          "pages",
          <div css={headingContainerStyles}>
            <LargeHeadline>{site.welcomeHeading}</LargeHeadline>
          </div>,
        )}
        {site.welcomeSubheading && (
          <div css={subheadingContainerStyles}>
            <SmallHeadline>{site.welcomeSubheading}</SmallHeadline>
          </div>
        )}
        {site.shouldDisplayFrontOfCard && (
          <div css={cardStyles}>
            <TitleCardSection />
          </div>
        )}
      </div>
      {connectRef("sections", "pages", <div />)}
      <div>
        <ContentSections isHolidaySite isMobileView={isMobileView} />
        {!isPreview && !isCommentSectionInViewport && (
          <CommentStickyFooter
            comments={comments}
            commentSectionRef={commentSectionRef}
            isReadOnly={site.isDemoSite || site.status === "archived"}
          />
        )}
      </div>
      <div css={grayBackgroundStyles} ref={commentSectionRef}>
        <CommentSection
          isReadOnly={
            isPreview || site.isDemoSite || site.status === "archived"
          }
        />
        <Footer siteType={site.siteType} />
      </div>
    </Fragment>
  );
};

HolidayWebsiteContent.propTypes = propTypes;

const mapStateToProps = (state) => {
  const comments = getResource(state.resources.comments);
  const hasSections = Object.keys(state.resources.sections.byId).length > 0;
  const site = selectSite(state.resources.sites);

  return {
    comments,
    hasSections,
    site,
  };
};

export default RefSource(connect(mapStateToProps)(HolidayWebsiteContent));
