import { SITE_TYPES } from "./constants";
import PropTypes from "prop-types";

const getSiteTypeData = ({ siteType }) => {
  const isHolidaySite = siteType === SITE_TYPES.HOLIDAY;
  const isOnlineInvitationSite = siteType === SITE_TYPES.ONLINE_INVITATION;
  const isWeddingSite = siteType === SITE_TYPES.WEDDING;

  return {
    isHolidaySite,
    isOnlineInvitationSite,
    isWeddingSite,
  };
};

getSiteTypeData.propTypes = {
  siteType: PropTypes.string.isRequired,
};

export default getSiteTypeData;
