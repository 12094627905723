export const ATTENDING = "attending";
export const NOT_ATTENDING = "notAttending";
export const AWAITING_REPLY = "awaitingReply";

/*
  SENT and UNSENT are used for Online Invitation
  They are a more specific version of awaiting reply
*/
export const SENT = "sent";
export const UNSENT = "unsent";

export const ATTENDING_STATUS_LABEL = {
  [ATTENDING]: "Attending",
  [AWAITING_REPLY]: "Awaiting Reply",
  [NOT_ATTENDING]: "Not Attending",
  [SENT]: "Sent Invitation",
  [UNSENT]: "Unsent Invitation",
};
