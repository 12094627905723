import { css } from "@emotion/react";
import { theme } from "@minted/minted-components";

export const smMarginRightStyles = css`
  margin-right: ${theme.spacing.x3};
`;

export const medMarginRightStyles = css`
  margin-right: ${theme.spacing.x6};
`;

export const lgMarginRightStyles = css`
  margin-right: ${theme.spacing.x8};
`;

export const smMarginBottomStyles = css`
  margin-bottom: ${theme.spacing.x3};
`;

export const medMarginBottomStyles = css`
  margin-bottom: ${theme.spacing.x6};
`;

export const lgMarginBottomStyles = css`
  margin-bottom: ${theme.spacing.x8};
`;

export const xlMarginBottomStyles = css`
  margin-bottom: ${theme.spacing.x12};
`;

export const xxlMarginBottomStyles = css`
  margin-bottom: ${theme.spacing.x16};
`;

export const smMarginTopStyles = css`
  margin-top: ${theme.spacing.x3};
`;
