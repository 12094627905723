import OnlineInvitationExclusions from "./OnlineInvitationExclusions";
import { css } from "@emotion/react";
import {
  Button,
  Logo,
  styleUtils,
  TextLink,
  theme,
} from "@minted/minted-components";
import config from "config";
import React from "react";
import { useState } from "react";

const onlineInvitationSignupStyles = {
  descriptionStyles: css`
    ${theme.typeStyles.bodySmallStyles};
    ${theme.typeStyles.bodyBoldStyles};

    margin: auto;
    margin-bottom: ${theme.spacing.x2};
    text-align: center;
  `,
  headerStyles: css`
    ${theme.typeStyles.bodyLargeStyles};
    ${theme.typeStyles.bodyBoldStyles};

    margin: auto;
    margin-top: ${theme.spacing.base};
    margin-bottom: ${theme.spacing.base};
    text-align: center;
  `,
  inputWrapperStyles: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: ${theme.spacing.x3};
  `,
  logoWrapperStyles: css`
    width: ${styleUtils.rem(60)};
    margin: auto;
  `,
  seeDetailsStyles: css`
    margin: auto;
  `,
  signupWrapperStyles: css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.x4};
    background-image: url(https://cdn3.minted.com/files/hitchedup/DigitalInvites.jpg);
    background-size: cover;
    background-position: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  `,
};

const loginServiceHost =
  config.get("loginServiceHost") ||
  `https://login.${window.location.href.split("/")[2].split(".").slice(1).join(".")}`;

const cypressScope = "signupCallout";

const OnlineInvitationSignup = ({ siteId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div
      css={onlineInvitationSignupStyles.signupWrapperStyles}
      data-cy={cypressScope}
    >
      <div css={onlineInvitationSignupStyles.logoWrapperStyles}>
        <Logo modifier="minted" />
      </div>
      <div
        css={onlineInvitationSignupStyles.headerStyles}
        data-cy={`${cypressScope}Header`}
      >
        Join the Party
      </div>
      <div
        css={onlineInvitationSignupStyles.descriptionStyles}
        data-cy={`${cypressScope}Description`}
      >
        Sign up for a Minted account and receive a $25 credit towards your next
        purchase
      </div>
      <div
        css={onlineInvitationSignupStyles.inputWrapperStyles}
        data-cy={`${cypressScope}Button`}
      >
        <Button
          // source below is used by analytics to match new accounts with sites
          href={
            loginServiceHost +
            `/register?source=onlineinvitation_guestrsvp_${siteId}`
          }
          size={Button.sizes.small}
          target="_blank"
          text="Create an account"
          type={Button.types.secondary}
        />
      </div>
      <div
        css={onlineInvitationSignupStyles.seeDetailsStyles}
        data-cy={`${cypressScope}SeeDetails`}
      >
        <TextLink onClick={() => setIsModalOpen(true)} text="See details" />
      </div>
      <OnlineInvitationExclusions
        close={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
      />
    </div>
  );
};

export default OnlineInvitationSignup;
