import drf from "./drf";
import { APIError } from "./errors";
import config from "config";
import merge from "lodash/merge";
import pick from "lodash/pick";

const mergeSettings = (oldSettings, newSettings) => {
  newSettings = pick(newSettings, Object.keys(defaultSettings));

  return merge({}, oldSettings, newSettings);
};

const defaultSettings = {
  basePath: "api",
  domain: config.get("apiDomain"),
  hasMultipleQueryParamValues: false,
  headers: {},
  returnCookies: false,
  scheme: config.get("apiScheme"),
  trailingSlash: true,
  useHeaderPagination: false,
};

class API {
  constructor() {
    this.reset();
    this.backend = drf;
  }

  reset() {
    this.settings = defaultSettings;
  }

  configure(settings) {
    this.settings = mergeSettings(this.settings, settings);
  }

  get(resource, overrides, params, casingIgnorePaths) {
    const settings = mergeSettings(this.settings, overrides);

    return this.backend.get(resource, params, settings, casingIgnorePaths);
  }

  patch(resource, overrides, data, casingIgnorePaths) {
    const settings = mergeSettings(this.settings, overrides);

    return this.backend.patch(resource, data, settings, casingIgnorePaths);
  }

  post(resource, overrides, data, casingIgnorePaths) {
    const settings = mergeSettings(this.settings, overrides);

    return this.backend.post(resource, data, settings, casingIgnorePaths);
  }

  put(resource, overrides, data, casingIgnorePaths) {
    const settings = mergeSettings(this.settings, overrides);

    return this.backend.put(resource, data, settings, casingIgnorePaths);
  }

  delete(resource, data, overrides, casingIgnorePaths) {
    const settings = mergeSettings(this.settings, overrides);

    return this.backend.delete(resource, data, settings, casingIgnorePaths);
  }

  options(resource) {
    return this.backend.options(resource, this.settings);
  }

  url(resource, overrides, params) {
    const settings = mergeSettings(this.settings, overrides);

    return this.backend.makeUrl(resource, params, settings);
  }
}

const apiConfig = {
  domain: config.get("apiDomain"),
  scheme: config.get("apiScheme"),
};

export default new API();
export { API, apiConfig, APIError };
