const initialState = {
  assetTransitioning: null,
  selectedAsset: null, // Temporary hack, there are better ways to do this when we come back to photos
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "ASSET_OPENED":
      return {
        ...state,
        selectedAsset: action.payload.assetSpec,
      };
    case "ASSET_CLOSED":
      return {
        ...state,
        selectedAsset: null,
      };
    case "ASSET_TRANSITION_START": // TODO: Kill this off
      return {
        ...state,
        assetTransitioning: action.payload.key,
      };
    case "ASSET_TRANSITION_END": // TODO: Kill this off
      return {
        ...state,
        assetTransitioning: null,
      };
    default:
      return state;
  }
};
