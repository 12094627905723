import get from "lodash/get";

export default class RefManager {
  constructor() {
    this.refs = {};
    this.listeners = [];
  }

  setRef(id, namespace, ref) {
    if (!(namespace in this.refs)) {
      this.refs[namespace] = {};
    }

    this.refs[namespace][id] = ref;
    this.callListeners();
  }

  addListener(listener) {
    this.listeners.push(listener);
  }

  removeListener(listener) {
    const pos = this.listeners.indexOf(listener);

    if (pos !== -1) {
      this.listeners.splice(pos, 1);
    }
  }

  getRefs() {
    return this.refs;
  }

  callListeners() {
    this.listeners.forEach((listener) => listener());
  }

  waitForRef(id, namespace) {
    return new Promise((resolve, reject) => {
      let ref = get(this.refs, `${namespace}.${id}`);

      if (ref != null) {
        resolve(ref);
      }

      const listener = () => {
        ref = this.refs[namespace][id];

        if (ref != null) {
          this.removeListener(listener);
          resolve(ref);
        }
      };

      this.addListener(listener);
    });
  }
}
