import { WrapperLarge, WrapperXLarge } from "../../layout/website";
import Page from "../../pages/website/Page";
import RefSource from "../../ref-monitor/RefSource";
import { selectCollection } from "../../resources/selectors";
import ContentSections from "../../website/components/ContentSections";
import EmptyPage from "../../website/components/EmptyPage";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

class Photos extends Component {
  render() {
    const {
      configuration,
      dashboardUrl,
      hasSections,
      id,
      isMultiPage,
      pageId,
      site,
      title,
    } = this.props;

    const Wrapper = isMultiPage ? WrapperXLarge : WrapperLarge;

    return hasSections ? (
      <Page id={id} title={title}>
        <Wrapper>
          <ContentSections pageId={pageId} />
        </Wrapper>
      </Page>
    ) : (
      <div>
        <EmptyPage
          configuration={configuration}
          headline="You haven't added any media yet."
          id={id}
          title={title}
        >
          Once you've added media in the{" "}
          <a href={`${dashboardUrl(site.id)}/pages/photos`} target="_parent">
            Wedding Website Manager
          </a>
          , they will show up here with all the details.
        </EmptyPage>
      </div>
    );
  }
}

Photos.propTypes = {
  dashboardUrl: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isMultiPage: PropTypes.bool,
  sections: PropTypes.arrayOf(PropTypes.object),
  site: PropTypes.object,
  textAlignment: PropTypes.any,
  title: PropTypes.string.isRequired,
};
const mapStateToProps = (state, props) => {
  const { pageId } = props;

  const page =
    pageId || selectCollection(state.resources.pages).items[0].data.id;

  const collection = selectCollection(
    state.resources.sections,
    {
      page,
    },
    {
      includeStaged: true,
    },
  );

  const hasSections =
    [...collection.items, ...collection.resource.staged.create].length > 0;

  return {
    hasSections,
  };
};

export default RefSource(connect(mapStateToProps)(Photos));
