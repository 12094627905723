import getSiteTypeData from "../core/getSiteTypeData";
import {
  ContentText,
  ContentWrapper,
  HeadlineSmall,
  HeadlineMedium,
  Image,
  RichTextContent,
} from "./components";
import { configurationPropTypes } from "./configuration";
import { ActionLink } from "@minted/fancyclothes";
import { array, func, shape, string } from "prop-types";
import React from "react";

export const Content = ({
  actionLinkProps,
  configuration,
  description,
  image,
  siteType,
  title,
  url,
}) => {
  const { isOnlineInvitationSite } = getSiteTypeData({
    siteType,
  });
  // OI fakes the section content to look like page content, so the header
  // needs to match the page header size
  const HeadlineComponent = isOnlineInvitationSite
    ? HeadlineMedium
    : HeadlineSmall;

  return (
    <ContentWrapper
      imageAlignment={configuration.imageAlignment}
      textAlignment={configuration.textAlignment}
    >
      <Image
        image={image}
        imageAlignment={configuration.imageAlignment}
        imageSize={configuration.imageSize}
      />
      <ContentText textAlignment={configuration.textAlignment}>
        <div data-cy="subheading">
          <HeadlineComponent>{title}</HeadlineComponent>
        </div>
        <div data-cy="body">
          <RichTextContent value={description} />
          {url && actionLinkProps && (
            <ActionLink target="_blank" url={url} {...actionLinkProps} />
          )}
        </div>
      </ContentText>
    </ContentWrapper>
  );
};

Content.propTypes = {
  actionLinkProps: shape({
    onClick: func.isRequired,
    text: string.isRequired,
  }),
  configuration: configurationPropTypes,
  description: array,
  image: string,
  siteType: string,
  title: string.isRequired,
  url: string,
};
