import config from "config";
import React, { useEffect, useRef } from "react";

// using emotion css didn't work for the site preview
const videoPlayerStyles = {
  height: "100%",
};

export default ({ publicId }) => {
  const video = useRef(null);

  useEffect(() => {
    const init = () =>
      // eslint-disable-next-line no-undef
      cloudinary.videoPlayer(video.current, {
        autoplay: true,
        cloudName: config.get("cloudinaryCloudName"),
        mute: true,
        preload: "auto",
        publicId,
        showLogo: false,
        sourceTypes: ["dash", "hls"],
        transformation: {
          streaming_profile: "full_hd_lean",
        },
      });

    const player = init();

    return () => player.dispose();
  }, [publicId, video]);

  return (
    <div style={videoPlayerStyles}>
      <video
        className="cld-video-player vjs-fill"
        controls
        id={publicId}
        ref={video}
      />
    </div>
  );
};
