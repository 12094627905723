import { css } from "@emotion/react";
import { theme } from "@minted/minted-components";
import PropTypes from "prop-types";
import React from "react";

const columns = 12;

const style = {
  base: css`
    margin: 0 auto;
    max-width: 1170px;
    box-sizing: border-box;
  `,
  large: css`
    ${theme.media.greaterThan(theme.breakpoints.medium)(`
      margin: 0 ${100 / columns}%; // Pad with 1 column on each side
    `)}
  `,
  medium: css`
    // TODO: This feels weird that it's adding more padding at larger widths, but with a constant container width,
    //   this means that the content is larger at smaller page width, and smaller at larger page widths.
    //   Is this backwards?
    ${theme.media.greaterThan(theme.breakpoints.medium)(`
      margin: 0 ${100 / columns}%; // Pad with 1 column on each side
    `)}

    ${theme.media.greaterThan(theme.breakpoints.large)(`
      margin: 0 ${(100 / columns) * 2}%; // Pad with 2 columns on each side
    `)}
  `,
  xLarge: css`
    ${theme.media.greaterThan(theme.breakpoints.large)(`
      margin: 0 auto; // No padding on sides
    `)}
  `,
};

const Wrapper = ({ children, size }) => (
  <div css={style.base}>
    <div css={size}>{children}</div>
  </div>
);

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.object.isRequired,
};

export const WrapperXLarge = ({ children }) => (
  <Wrapper size={style.xLarge}>{children}</Wrapper>
);

export const WrapperLarge = ({ children }) => (
  <Wrapper size={style.large}>{children}</Wrapper>
);

export const WrapperMedium = ({ children }) => (
  <Wrapper size={style.medium}>{children}</Wrapper>
);
