import { css } from "@emotion/react";
import { theme } from "@minted/minted-components";
import React from "react";

const style = css`
  margin-bottom: 20px;
  padding: 0 15px;

  ${theme.media.greaterThan(theme.breakpoints.small)(`
    flex: 0 0 40%;
    margin-bottom: 0;
  `)}
`;

export default ({ children }) => <div css={style}>{children}</div>;
