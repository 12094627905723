export const SITE_TYPES = {
  HOLIDAY: "holiday",
  ONLINE_INVITATION: "online_invitation",
  WEDDING: "wedding",
};

export const VIDEO_LIMIT = 20;
export const PHOTO_LIMIT = 1000;

export const MAX_DOMAIN_LENGTH = 64;
export const MAX_PASSWORD_LENGTH = 30;

export const GUESTS_TOKEN_EXPIRE_TIME_SECONDS = 60 * 60 * 24 * 3; // 3 Days

export const getSlateDocFromText = (content) => {
  if (typeof content === "string") {
    return [
      {
        children: [
          {
            text: content,
          },
        ],
        type: "paragraph",
      },
    ];
  }

  return [
    {
      children: content.map((textItem) => ({
        children: [
          {
            text: textItem,
          },
        ],
        type: "list-item",
      })),
      type: "bulleted-list",
    },
  ];
};

export const BLANK_SLATE_DOCUMENT = getSlateDocFromText("");

export const PREVIEW_IFRAME_ID = "pagePreviewIframe";
