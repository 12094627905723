import { lgMarginBottomStyles } from "../../neutral/styles/spacing";
import { SmallDivider } from "@minted/fancyclothes";
import React from "react";

const centered = {
  textAlign: "center",
};

export default ({ extraStyles = [], hideDivider = false, isCentered }) => (
  <div
    css={[lgMarginBottomStyles, isCentered && centered, ...extraStyles]}
    data-cy="secondarySeparator"
  >
    {!hideDivider && <SmallDivider />}
  </div>
);
