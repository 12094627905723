import PropTypes from "prop-types";
import { Children, Component } from "react";

class RefMonitorProvider extends Component {
  constructor(props, context) {
    super(props, context);

    this.manager = this.props.refManager;
  }

  getChildContext() {
    return {
      refManager: this.manager,
    };
  }

  render() {
    return Children.only(this.props.children);
  }
}

RefMonitorProvider.propTypes = {
  refManager: PropTypes.object,
};

RefMonitorProvider.childContextTypes = {
  refManager: PropTypes.object,
};

export default RefMonitorProvider;
