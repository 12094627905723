import TextAlignmentContext from "../../../dashboard/TextAlignmentContext";
import { Element, Leaf } from "./rendering";
import { css } from "@emotion/react";
import React, { useMemo, useCallback, useContext } from "react";
import { createEditor } from "slate";
import { Slate, Editable, withReact } from "slate-react";

// https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript
const hashString = (str) => {
  if (!str) {
    return "";
  }
  let hash = 0;

  for (let index = 0, len = str.length; index < len; index++) {
    const chr = str.charCodeAt(index);

    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
};

export default ({ value }) => {
  const editor = useMemo(() => withReact(createEditor()), []);

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const textAlignment = useContext(TextAlignmentContext); // For displaying alignment on the site editor

  return (
    <div
      css={css`
        text-align: ${textAlignment};
      `}
    >
      <Slate
        editor={editor}
        initialValue={value}
        // Slate doesn't update when the value is changed. Doing this to force a re-render on value change.
        key={hashString(JSON.stringify(value))}
      >
        <Editable
          readOnly
          renderElement={renderElement}
          renderLeaf={renderLeaf}
        />
      </Slate>
    </div>
  );
};
