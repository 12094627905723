import { countryNameByCode } from "../../guest-management/forms/constants";
import MintedLogo from "../../navigation/dashboard/MintedLogo";
import EventDateTime from "./EventDateTime";
import { css } from "@emotion/react";
import { Button, Icon } from "@minted/minted-components";
import { styleUtils, theme } from "@minted/minted-components";
import React from "react";

// const buttonPaddingStyles = css`
//   // Removes padding from MC Button since it messes with alignment.
//   padding: 0;
// `;

const confirmationStyles = {
  centeredLogoStyles: css`
    align-items: center;
    display: flex;
    justify-content: center;
  `,
  contentStyles: css`
    margin: 0 auto;
    max-width: ${styleUtils.rem(500)};
    padding-left: ${theme.spacing.x4};
    padding-right: ${theme.spacing.x4};
  `,
  eventTitleStyles: css`
    ${theme.typeStyles.bodySmallStyles};
    ${theme.typeStyles.bodyBoldStyles};
  `,
  flexContainerStyles: css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
  `,
  headlineStyles: css`
    ${theme.typeStyles.bodyLargeStyles};
    margin-bottom: ${theme.spacing.x8};
    margin-top: ${theme.spacing.x4};
    text-align: center;
  `,
  responseTextStyles: css`
    ${theme.typeStyles.bodySmallStyles};
    margin-left: 15%;
    text-align: right;
  `,
  sectionSpacerStyles: css`
    border: 1px solid ${theme.colors.gray300};
    margin-bottom: ${theme.spacing.x8};
    margin-top: ${theme.spacing.x8};
  `,
  spacingStyles: css`
    margin-bottom: ${theme.spacing.x6};
  `,
  subHeadingStyles: css`
    ${theme.typeStyles.titleSmallStyles};
  `,
  textStyles: css`
    ${theme.typeStyles.bodySmallStyles};
  `,
};

const getQuestionsById = (questions) =>
  questions.reduce((obj, question) => {
    obj[question.id] = question.text;

    return obj;
  }, {});

const Answers = ({ answersData, questionsById }) =>
  answersData.map((answerData) => (
    <div
      css={[
        confirmationStyles.flexContainerStyles,
        confirmationStyles.spacingStyles,
      ]}
      key={answerData.question}
    >
      <div css={confirmationStyles.textStyles}>
        {questionsById[answerData.question]}
      </div>
      <div css={confirmationStyles.responseTextStyles}>{answerData.text}</div>
    </div>
  ));

const ResponseEvents = ({ responseEvents }) =>
  responseEvents.map((response) => {
    const questionsById = getQuestionsById(response.event.questions);

    return (
      <div key={response.id}>
        <div>
          <div css={confirmationStyles.flexContainerStyles}>
            <div css={confirmationStyles.eventTitleStyles}>
              {response.event.title}
            </div>
            <div css={confirmationStyles.textStyles}>
              {response.attending ? " Yes" : " No"}
            </div>
          </div>
          <div css={confirmationStyles.textStyles}>
            <EventDateTime event={response.event} />
            {response.event.venue &&
              response.event.venue !== response.event.address1 && (
                <>
                  {response.event.venue}
                  <br />
                </>
              )}
            {response.event.hasPhysicalAddress && (
              <span>
                {response.event.address1},{" "}
                {response.event.address2 ? `${response.event.address2}, ` : ""}
                {response.event.city}, {response.event.state}{" "}
                {response.event.zipCode}
                {response.event.country && response.event.country !== "US" && (
                  <>
                    {", "}
                    {countryNameByCode[response.event.country]}
                  </>
                )}
              </span>
            )}
          </div>
        </div>
        <Answers answersData={response.answers} questionsById={questionsById} />
        <hr css={confirmationStyles.sectionSpacerStyles} />
      </div>
    );
  });

export default ({ guestName, onChangeResponses, responses }) => {
  const sortedResponses = [...responses].sort(
    (responseA, responseB) =>
      responseA.event.position - responseB.event.position,
  );

  return (
    <div css={confirmationStyles.contentStyles}>
      <div css={confirmationStyles.centeredLogoStyles}>
        <MintedLogo />
      </div>
      <div css={confirmationStyles.headlineStyles}>
        Thank you for responding!
      </div>
      <div
        css={[
          confirmationStyles.flexContainerStyles,
          confirmationStyles.spacingStyles,
        ]}
      >
        <div css={confirmationStyles.subHeadingStyles}>
          Event Summary for {guestName}
        </div>
      </div>
      <div
        css={[
          confirmationStyles.flexContainerStyles,
          confirmationStyles.spacingStyles,
        ]}
      >
        <div css={confirmationStyles.textStyles}>Number in Party:</div>
        <div css={confirmationStyles.textStyles}>
          {responses[0].guest?.party}
        </div>
      </div>
      <hr css={confirmationStyles.sectionSpacerStyles} />
      <div
        css={[
          confirmationStyles.flexContainerStyles,
          confirmationStyles.spacingStyles,
        ]}
      >
        <div css={confirmationStyles.subHeadingStyles}>Events Attending</div>
        <Button
          iconAlignment={Button.iconAlignments.right}
          iconType={Icon.types.pencil}
          onClick={onChangeResponses}
          text="Change Responses"
          type={Button.types.tertiary}
        />
      </div>

      <ResponseEvents responseEvents={sortedResponses} />
    </div>
  );
};
