export const commentValidations = [
  {
    errorMessage: "Required",
    getIsValid: ({ value }) => value,
  },
];

export const emailRegexp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const emailValidations = [
  {
    errorMessage: "Required",
    getIsValid: ({ value }) => value,
  },
  {
    errorMessage: "Email is invalid",
    getIsValid: ({ value }) => emailRegexp.test(value),
  },
];

const nameRegexp = /[^ a-zA-Z'-]\d/g;

export const nameValidations = [
  {
    errorMessage: "Required",
    getIsValid: ({ value }) => value,
  },
  {
    errorMessage: "Name contains invalid characters",
    getIsValid: ({ value }) => !nameRegexp.test(value),
  },
  {
    errorMessage: "Name contains over 50 characters.",
    getIsValid: ({ value }) => value.length <= 50,
  },
];
