import PropTypes from "prop-types";
import { Children, Component } from "react";

class ScrollerProvider extends Component {
  constructor(props, context) {
    super(props, context);

    this.scroller = this.props.scroller;
  }

  getChildContext() {
    return {
      scroller: this.scroller,
    };
  }

  render() {
    return Children.only(this.props.children);
  }
}

ScrollerProvider.propTypes = {
  scroller: PropTypes.object,
};

ScrollerProvider.childContextTypes = {
  scroller: PropTypes.object,
};

export default ScrollerProvider;
