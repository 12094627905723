import API from "../core/api";
import { openModal } from "../modals/actions";
import {
  clearChanges,
  clearCreation,
  clearRemoval,
  commitChanges,
  commitCreation,
  commitRemoval,
  customPost,
  fetchResource,
  invalidateCollections,
  stageChanges,
  stageCreation,
  stageRemoval,
} from "../resources/actions";
import { track } from "../track/utils";
import { getGuestTokenCookie } from "../website/actions";

const model = "guests";

const updateGuest = (id, data) => (dispatch) => {
  dispatch(
    stageChanges(model, id, data, {
      silent: true,
    }),
  );

  return dispatch(commitChanges(model, id)).catch((error) =>
    dispatch(clearChanges(model, id)),
  );
};

const openEditRsvpModal = (data) => (dispatch) =>
  dispatch(openModal("EDIT_RSVP_MODAL", data));

const openGuestDownloadModal = () => (dispatch) =>
  dispatch(openModal("GUEST_DOWNLOAD_MODAL", {}));

const openEmailGuestsModal = () => (dispatch) =>
  dispatch(openModal("EMAIL_GUESTS_MODAL", {}));

const openRSVPReminderModal = () => (dispatch) =>
  dispatch(openModal("RSVP_REMINDER_MODAL", {}));

const sendGuestEmails = (data) => (dispatch) =>
  dispatch(customPost(`${model}/email`, data, "email-guests", {}));

const sendRSVPReminders = (data) => (dispatch) =>
  dispatch(customPost(`${model}/rsvp_reminder`, data, "rsvp-reminders"));

const updateGuestRsvps = (data) => (dispatch) => {
  const headers = {};
  const guestToken = getGuestTokenCookie();

  if (Boolean(guestToken)) {
    // If the host is updating RSVPs from their dashboard, they won't have a guest token,
    // and it will fall back on logged-in auth
    headers["Authorization"] = `Guest ${guestToken}`;
  }

  return API.post(
    "rsvps/for_guest",
    {
      headers,
    },
    data,
  ).then((result) => {
    const groups = {
      guests: [result[0].guest],
      rsvps: result,
    };

    Object.entries(groups).forEach(([group, items]) => {
      dispatch({
        meta: {
          model: group,
          params: {},
        },
        payload: items,
        type: "MERGE_RESOURCES",
      });
    });
  });
};

// TODO: REMOVE WHEN LEGACY ADDRESS BOOK GOES AWAY
const normalizeInvites = (guests) =>
  guests.reduce(
    (obj, guest) => {
      const invites = guest.invites;

      guest.invites = invites.map((invite) => invite.id);
      obj.guests.push(guest);
      obj.invites.push(...invites);

      return obj;
    },
    {
      guests: [],
      invites: [],
    },
  );

const createGuests = (guests) => (dispatch) => {
  guests.forEach(() => {
    track("Add Guest", "Guest_Added");
  });
  const progressKey = "add-guests";

  return dispatch(customPost(model, guests, progressKey)).then((result) => {
    Object.entries(normalizeInvites(result.value)).forEach(([group, items]) => {
      dispatch({
        meta: {
          model: group,
          params: {},
        },
        payload: items,
        type: "MERGE_RESOURCES",
      });
    });

    return dispatch(invalidateCollections("events"));
  });
};

const deleteGuests = (guests) => (dispatch) =>
  API.delete("guests/delete", guests).then((result) => {
    dispatch(invalidateCollections("events"));
    dispatch(invalidateCollections("guests"));
  });

export default {
  clearChanges: (id) => clearChanges(model, id),
  clearCreation: (params) => clearCreation(model, params),
  clearRemoval: (id) => clearRemoval(model, id),
  commitChanges: (id) => commitChanges(model, id),
  commitCreation: (params) => commitCreation(model, params),
  commitRemoval: (id) => commitRemoval(model, model, id, undefined, {}, true),
  createGuests,
  deleteGuests,
  fetch: () => fetchResource(model, model),
  openEditRsvpModal,
  openEmailGuestsModal,
  openGuestDownloadModal,
  openRSVPReminderModal,
  sendGuestEmails,
  sendRSVPReminders,
  stageChanges: (id, data) => stageChanges(model, id, data),
  stageCreation: (params, data) => stageCreation(model, data, params),
  stageRemoval: (id) => stageRemoval(model, id),
  updateGuest,
  updateGuestRsvps,
};
