import { selectSite } from "../../selectors";
import { css } from "@emotion/react";
import { theme } from "@minted/minted-components";
import PropTypes from "prop-types";
import { React } from "react";
import { connect } from "react-redux";

const imageStyles = css`
  display: block;
  margin: auto;
  max-width: 100%;
  padding-top: ${theme.spacing.x16};
`;

const propTypes = {
  site: PropTypes.shape({
    savedDesignImage: PropTypes.shape({
      original: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export const TitleCardSection = ({ site }) => (
  <img css={imageStyles} src={site.savedDesignImage?.original} />
);

const mapStateToProps = (state) => {
  const site = selectSite(state.resources.sites);

  return {
    site,
  };
};

TitleCardSection.propTypes = propTypes;

export default connect(mapStateToProps)(TitleCardSection);
