import getSiteTypeData from "../../core/getSiteTypeData.js";
import lockViewToPage from "../lockViewToPage";
import { getSlugFromRoute } from "../router.js";
import ActivePageMonitor from "./ActivePageMonitor";

export default class SinglePageTransitionBackend {
  constructor(refManager, navigateTo, scroller, siteType) {
    this.refManager = refManager;
    this.navigateTo = navigateTo;
    this.scroller = scroller;
    this.siteType = siteType;
  }

  enable({ currentRoute, isCustomizePage, isInitialLoad }) {
    const { isWeddingSite } = getSiteTypeData({
      siteType: this.siteType,
    });

    this.activePageMonitor = new ActivePageMonitor(
      this.refManager,
      currentRoute.name,
      this.navigateTo,
    );

    if (isInitialLoad && isWeddingSite) {
      // non-wedding site types have a single page without section navigation
      // we don't need to scroll to the correct portion of the page based on the route
      this.disableViewLock = lockViewToPage(
        getSlugFromRoute({
          isCustomizePage,
          routeName: currentRoute.name,
          siteType: this.siteType,
        }),
        this.scroller,
        () => {
          this.activePageMonitor.enable();
        },
      );
    } else {
      this.activePageMonitor.enable();
    }
  }

  disable() {
    if (this.disableViewLock != null) {
      this.disableViewLock();
    }

    if (this.activePageMonitor) {
      // When un-deleting a site, is null
      this.activePageMonitor.disable();
    }
  }

  transitionTo = (pageName) => {
    this.scroller.scrollTo(pageName, "pages");
  };
}
