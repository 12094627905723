import Track from "../core/instrumentation";
import noop from "lodash/noop";
import React, { createContext, useCallback } from "react";

const initialState = {
  emitSiteEvent: noop,
};

const isProduction = process.env.NODE_ENV === "production";

export const TrackContext = createContext(initialState);

export const TrackContextProvider = ({ children, site }) => {
  const emitSiteEvent = useCallback(
    (payload) => {
      if (isProduction) {
        Track.emit({
          ...payload,
          site_id: site.id,
          site_type: site.siteType,
        });
      } else {
        console.info({
          ...payload,
          site_id: site.id,
          site_type: site.siteType,
        });
      }
    },
    [site.id, site.siteType],
  );

  return (
    <TrackContext.Provider
      value={{
        emitSiteEvent,
      }}
    >
      {children}
    </TrackContext.Provider>
  );
};
