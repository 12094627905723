const initialState = {
  facts: null,
  guestRsvpSelectedEventId: null,
  isFetching: false,
  matchingProducts: null,
  rsvpSummary: null,
  upgradePrice: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "DASHBOARD_SET_IS_FETCHING":
      return {
        ...state,
        isFetching: action.payload,
      };
    case "DASHBOARD_FACTS_FETCHED":
      return {
        ...state,
        facts: action.payload,
      };
    case "DASHBOARD_RSVP_SUMMARY_FETCHED":
      return {
        ...state,
        rsvpSummary: action.payload,
      };
    case "DASHBOARD_UPGRADE_PRICE_FETCHED":
      return {
        ...state,
        upgradePrice: action.payload,
      };
    case "DASHBOARD_MATCHING_PRODUCTS_FETCHED":
      return {
        ...state,
        matchingProducts: action.payload,
      };
    case "DASHBOARD_SET_GUEST_RSVP_SELECTED_EVENT_ID":
      return {
        ...state,
        guestRsvpSelectedEventId: action.payload,
      };
    default:
      return state;
  }
};
