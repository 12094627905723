import Track from "../../../core/instrumentation";
import { createComment } from "../../actions";
import { selectSite } from "../../selectors";
import {
  commentValidations,
  emailValidations,
  nameValidations,
} from "../../validations";
import { css } from "@emotion/react";
import {
  Alert,
  Drawer,
  TextInput,
  Checkbox,
  Textarea,
  theme,
  Button,
  LoadingSpinner,
  useMediaQueryState,
} from "@minted/minted-components";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { React, useState, useMemo } from "react";
import { connect, useDispatch } from "react-redux";

const validations = {
  authorEmail: emailValidations,
  authorName: nameValidations,
  comment: commentValidations,
};

const CURRENT_USER_KEY = "currentUser";

const commentFormStyles = {
  container: css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x4};
    justify-content: center;
    padding-top: ${theme.spacing.x8};
    width: 100%;
  `,
  drawer: css`
    ${theme.media.lessThan("medium")`
      height: 100%;
      max-height: 100%;
      max-width: 100%;
      width: 100%;
    `};
  `,
  errorMessage: css`
    ${theme.typeStyles.bodyExtraSmallStyles};
    ${theme.typeStyles.bodyBoldStyles};
    color: ${theme.colors.negative800};
    padding-top: ${theme.spacing.x2};
  `,
  textInputWidth: css`
    width: 100%;
  `,
  title: css`
    ${theme.typeStyles.displayExtraSmallStyles};
  `,
};

const NewCommentForm = ({
  closeDrawer,
  isWritingComment,
  replyId,
  siteId,
  siteType,
}) => {
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState(
    typeof localStorage === "undefined"
      ? null
      : JSON.parse(localStorage.getItem(CURRENT_USER_KEY)),
  );
  const [formErrors, setFormErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [comment, setComment] = useState("");
  const [authorEmail, setAuthorEmail] = useState(currentUser?.email || "");
  const [authorName, setAuthorName] = useState(currentUser?.name || "");
  const [emailAuthorOnReplies, setEmailAuthorOnReplies] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(true);

  const onClose = () => {
    setFormErrors({});
    setComment("");
    closeDrawer();
  };

  const validateForm = ({ authorEmail, authorName, comment }) => {
    const errors = {};

    // Validate each field and assign error messages
    const emailError = validateField("authorEmail", authorEmail);

    if (emailError) {
      errors.authorEmail = emailError;
    }

    const nameError = validateField("authorName", authorName);

    if (nameError) {
      errors.authorName = nameError;
    }

    const commentError = validateField("comment", comment);

    if (commentError) {
      errors.comment = commentError;
    }

    return errors;
  };

  const validateField = (fieldName, value) => {
    const fieldValidations = validations[fieldName];

    for (let index = 0; index < fieldValidations.length; index++) {
      const validation = fieldValidations[index];

      if (
        !validation.getIsValid({
          value,
        })
      ) {
        return validation.errorMessage;
      }
    }

    return null;
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    setIsSaving(true);

    const errors = validateForm({
      authorEmail,
      authorName,
      comment,
    });

    // If there are errors, set them and stop form submission
    if (!isEmpty(errors)) {
      setFormErrors(errors);
      setIsSaving(false);

      return;
    }

    const formattedData = {
      authorEmail,
      authorName,
      comment,
      emailAuthorOnReplies,
      isSubscribed,
      siteId,
    };

    if (replyId) {
      formattedData.parentComment = replyId;
    }

    localStorage.setItem(
      CURRENT_USER_KEY,
      JSON.stringify({
        email: authorEmail,
        name: authorName,
      }),
    );
    setCurrentUser({
      email: authorEmail,
      name: authorName,
    });

    dispatch(createComment(formattedData))
      .then(() => {
        onClose();
        Track.emit({
          event: "guest_post_comment",
          feature: siteType,
          site_id: siteId,
        });
      })
      .catch(setFormErrors)
      .finally(() => {
        setIsSaving(false);
      });
  };

  const extraSmallMediaQueryState = useMediaQueryState({
    mediaQuerySize: "extraSmall",
  });

  const responsiveInputProps = useMemo(
    () => ({
      emailPlaceholder:
        extraSmallMediaQueryState === "ABOVE" ? "" : "Your email address",
      namePlaceholder: extraSmallMediaQueryState === "ABOVE" ? "" : "Your name",
      textInputSize: extraSmallMediaQueryState === "ABOVE" ? "small" : "medium",
    }),
    [extraSmallMediaQueryState],
  );

  return (
    <Drawer
      css={commentFormStyles.drawer}
      fullscreen
      onClose={onClose}
      onOverlayClick={onClose}
      show={isWritingComment}
    >
      <div css={commentFormStyles.container}>
        <div css={commentFormStyles.title}>Leave a comment</div>
        <LoadingSpinner
          alignment="center"
          loading={isSaving}
          variation="dark"
        />
        <div css={commentFormStyles.textInputWidth}>
          <Textarea
            minRows={4}
            name="comment"
            onChange={(event) => setComment(event.target.value)}
            placeholder="Write a comment..."
            required
            size={responsiveInputProps.textInputSize}
            value={comment}
          />
          {formErrors.comment && (
            <div css={commentFormStyles.errorMessage}>{formErrors.comment}</div>
          )}
        </div>
        <div css={commentFormStyles.textInputWidth}>
          <TextInput
            label="Your name"
            name="authorName"
            onChange={(event) => setAuthorName(event.target.value)}
            placeholder={responsiveInputProps.namePlaceholder}
            required
            size={responsiveInputProps.textInputSize}
            value={authorName}
          />
          {formErrors.authorName && (
            <div css={commentFormStyles.errorMessage}>
              {formErrors.authorName}
            </div>
          )}
        </div>
        <div css={commentFormStyles.textInputWidth}>
          <TextInput
            label="Your email address"
            name="authorEmail"
            onChange={(event) => setAuthorEmail(event.target.value)}
            placeholder={responsiveInputProps.emailPlaceholder}
            required
            size={responsiveInputProps.textInputSize}
            value={authorEmail}
          />
          {formErrors.authorEmail && (
            <div css={commentFormStyles.errorMessage}>
              {formErrors.authorEmail}
            </div>
          )}
        </div>
        <Alert secondaryText="Your email won't be displayed." show />
        <Checkbox
          additionalText="Subscribe to Minted email updates. New subscribers will receive a 20% off welcome offer."
          checked={isSubscribed}
          name="isSubscribed"
          onChange={(event) => setIsSubscribed(event.target.checked)}
          size="small"
        />

        <Checkbox
          additionalText="Send me emails when someone replies to this comment."
          checked={emailAuthorOnReplies}
          name="emailAuthorOnReplies"
          onChange={(event) => setEmailAuthorOnReplies(event.target.checked)}
          size={Checkbox.sizes.small}
        />

        <Button
          expand
          onClick={onFormSubmit}
          submit
          text="Post"
          type={Button.types.primary}
        />
        {!isEmpty(formErrors) && (
          <div css={commentFormStyles.errorMessage}>
            Comment failed to post. Please try again.
          </div>
        )}
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state) => {
  const site = selectSite(state.resources.sites);

  return {
    siteId: site.id,
    siteType: site.siteType,
  };
};

NewCommentForm.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  isWritingComment: PropTypes.bool,
  replyId: PropTypes.number,
  siteId: PropTypes.number.isRequired,
  siteType: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(NewCommentForm);
