import { DesignComponent } from "@minted/fancyclothes";
import Color from "color";
import PropTypes from "prop-types";
import React from "react";
import TopBarProgress from "react-topbar-progress-indicator";

class StyledTopBar extends DesignComponent {
  render() {
    const { shadowColor } = this.props;
    const baseColor = this.getRule("linkColor", "DesignProvider");
    const barColor = Color(baseColor).lighten(0.08).rgb().string();

    TopBarProgress.config({
      barColors: {
        0: barColor,
        "1.0": barColor,
      },
      shadowColor,
    });

    return <TopBarProgress />;
  }
}

StyledTopBar.propTypes = {
  shadowColor: PropTypes.string,
};

StyledTopBar.defaultProps = {
  shadowColor: "#000",
};

export default StyledTopBar;
