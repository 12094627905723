import { contentConfiguration, contentStyles } from "../../content";
import getSiteTypeData from "../../core/getSiteTypeData";
import Track from "../../core/instrumentation";
import { WrapperMedium } from "../../layout/website";
import EventMap from "../../map/website/Map";
import MapContainer from "../../map/website/MapContainer";
import Page from "../../pages/website/Page";
import FancyClothesContext from "../../website/components/FancyClothesContext";
import { focusLocation } from "../actions";
import { formatAddress } from "../utils";
import Event from "./Event";
import { css } from "@emotion/react";
import { Button } from "@minted/fancyclothes";
import config from "config";
import { arrayOf, array, shape, string } from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";

const googleMapsApiKey = config.get("googleMapsApiKey");

const buttonContainerStyles = css`
  display: flex;
  justify-content: center;
`;

const Events = ({
  configuration,
  dispatch,
  events,
  focusedLocationIndex,
  id,
  removeSpacing,
  siteType,
  title,
}) => {
  const [hasValidLocations, setHasValidLocations] = useState(false);

  const [isMapExpanded, setIsMapExtended] = useState(false);

  const [showMap, setShowMap] = useState(false);

  const { isOnlineInvitationSite, isWeddingSite } = getSiteTypeData({
    siteType,
  });

  const onExpandToggle = () => {
    setIsMapExtended((prevValue) => !prevValue);
  };

  const onShowToggle = () => {
    Track.emit({
      event: "map_button_click",
    });

    setShowMap(true);
  };

  const onLocationsGeocoded = (hasValidLocations) => {
    setHasValidLocations(hasValidLocations);
  };

  let hasAddresses;

  const locations = events.map((event) => {
    const formattedAddress = event.hasPhysicalAddress
      ? formatAddress(event)
      : "";

    // Track if any locations have an address
    hasAddresses = hasAddresses || Boolean(formattedAddress);

    return {
      ...event,
      formattedAddress,
    };
  });

  return (
    <FancyClothesContext.Consumer>
      {() => (
        <Page
          id={id}
          removeSpacing={removeSpacing}
          title={isOnlineInvitationSite ? "" : title}
        >
          <WrapperMedium>
            <div css={isMapExpanded && contentStyles.hideMd}>
              {locations.map((item, index) => (
                <Event
                  configuration={configuration}
                  index={index}
                  key={index}
                  showListNumber={configuration.showMap && hasValidLocations}
                  siteType={siteType}
                  {...item}
                />
              ))}
            </div>
            {isWeddingSite &&
              (configuration.showMap && hasAddresses ? (
                showMap ? (
                  <MapContainer
                    isExpanded={isMapExpanded}
                    onExpandToggle={onExpandToggle}
                  >
                    <EventMap
                      apiKey={googleMapsApiKey}
                      focusedLocationIndex={focusedLocationIndex}
                      focusLocation={(index) => dispatch(focusLocation(index))}
                      locations={locations}
                      onLocationsGeocoded={onLocationsGeocoded}
                    />
                  </MapContainer>
                ) : (
                  <div css={buttonContainerStyles}>
                    <Button onClick={onShowToggle} text="Show Map" />
                  </div>
                )
              ) : null)}
          </WrapperMedium>
        </Page>
      )}
    </FancyClothesContext.Consumer>
  );
};

Events.propTypes = {
  configuration: contentConfiguration.configurationPropTypes,
  events: arrayOf(
    shape({
      address1: string,
      address2: string,
      city: string,
      date: string.isRequired,
      description: array,
      state: string,
      time: string,
      title: string,
      venue: string.isRequired,
      zipCode: string,
    }).isRequired,
  ).isRequired,
  id: string.isRequired,
  title: string.isRequired,
};

const mapStateToProps = (state) => {
  const { focusedLocationIndex } = state.events;

  return {
    focusedLocationIndex,
  };
};

export default connect(mapStateToProps)(Events);
