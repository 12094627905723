import PropTypes from "prop-types";
import React from "react";

const style = {
  border: "8px solid #ffffff",
  boxShadow: "0 1px 4px rgba(0, 0, 0, 0.25)",
  boxSizing: "border-box",
  display: "block",
  height: "auto",
  width: "100%",
};

const Photo = ({ url }) => <img css={style} src={url} />;

Photo.propTypes = {
  url: PropTypes.string.isRequired,
};

export default Photo;
